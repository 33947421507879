/* Import Helpers in one location for styleurls */ /* prettier-ignore */
/* Legal theme design tokens */
/*
  Do not edit directly
  Generated on Wed, 28 Jul 2021 17:21:42 GMT
*/
/* Library specific mixins */
/* Color theme implementation
______________________________________ */
/* Import shared library tools. */
/* Import aliases - allows consumers to write shorter function names.
   Consume this file at your own risk as could overwrite existing functions. */
/*************************************************
	Aliases
	Description: Aliases to simplify writing the bento functions, mixins, and variables.
**************************************************/
/* Colors */
/* Z-index */
/* Ellipsis */
/* Word-wrap */
/* Font size - cannot use as bootstrap has conflicting named mixin */
/* Import tools for variables use */
/*************************************************
    List remove
    Description: Remove an item from a sass list
**************************************************/
/*************************************************
	Animations
    Description: Set animation settings.
    @param $count {number} - *Required - Animation-count, must be a number, time designation added in mixin.
    @param $duration {number} - *Required - Animation-duration, must be a number.
    @param $timingFunction {string} - *Required - Animation-timing-function
    @param $delay {number} - *Optional - Animation-delay, must be a numner, defaults to 0, time designation added in mixin.
**************************************************/
/*************************************************
	Ellipsis
	Description: Truncating sentences on a single line.
**************************************************/
/*************************************************
	Grid spacing
	Description: Grid spacing component used to apply smaller ratio margin and padding for mobile.
	Examples:
    .example {
        @include grid-margin(right, 4);
        @include grid-padding(right, 8);
    }
    .example2 {
        @include grid-margin(32, 4);
        @include grid-padding(32, 4);
    }
    .example3 {
        @include grid-margin(8, 40, 24);
        @include grid-padding(8, 40, 24);
    }
    .example4 {
        @include grid-margin(8, 16, 24, 32);
        @include grid-padding(8, 16, 24, 32);
    }
    .example5 {
        @include grid-margin(8);
        @include grid-padding(8);
    }

**************************************************/
/*************************************************
	Keyframes
    Description: Set keyframes with custom values for shareable animations.
    $animation-name: string = Animation-name attribute to call keyframe name.
    $attr: string = Css attribute to animate.(height, width, opacity, background-color, etc..)
    $start: number = Value at which the animation starts at. If animating height to open, 0 could be used.
    $end: number = Value at which the animation ends at.  If animating height to open, 250 could be used to set an expanded height.
**************************************************/
/*************************************************
	Number Function
    Description: Convert type-of from a string to a number.
    @param $string {string} - String to convert to a number.  Must be a number in a string.
**************************************************/
/*************************************************
	PX to REM converter
	Description: Changes pixel value to relative em units.
	Version: 1.0
**************************************************/
/*	Pixels to rem based on default font-size
______________________________________ */
/*************************************************
  Translate center
    Description: This mixin will center a child element both horizontally and vertically inside, no matter what the width of height of either are.
    Usage: Make sure parent element has position: relative.
**************************************************/
/* Token tools */
/* Function for flat sass map
    Description: Takes a design token as a parameter and returns it's value
______________________________________ */
/* Function for deep sass map @TODO Justin Breiland to update description
    Description: Takes a design token as a parameter and returns it's value
______________________________________ */
/* String to List
    Description: Turn a string into a list
______________________________________ */
/* Token Matcher
    Description: Matches a partial token, searches for indented path and returns full path if valid
______________________________________ */
/* Tools dependent on tokens */
/*************************************************
	Font Weights
	Description: Font weights for Source Sans Pro
**************************************************/
/* Function */
/* Font size & typesset must be import after your theme custom variables in theme helpers files */
/* Import tools for variables use */
/*************************************************
  Breakpoints
	Description: Breakpoints with @include-media npm.
**************************************************/
/*************************************************
	Colors Maps and function
	Description: Legal UX Color Palette
**************************************************/
/* SassScript Map */
/* Function */
/*************************************************
	Round function
    Description: Round with precision-round()
    Documentation: https://csspre.com/rounding-numbers/#RoundingNumbersCustomPrecisionScss
**************************************************/
/*************************************************
	Z-Index
  Description: System to manage sites z-indexes.
   Warning: Avoid customizing these values. They're used for a bird's eye view
   of components dependent on the z-axis and are designed to all work together.
**************************************************/
/* Function */
/*************************************************
  Box-shadow
	Description: Box-shadow elevations
**************************************************/
/* Import your product custom variables */
/*************************************************
	Legal color variables
  Description: Custom variables for configuring legal theme that are for colors only.
**************************************************/
/* Bootstrap $theme-colors overrides
   Must have primary, success, and danger replacements
______________________________________ */
/* Brand colors - this needs to be cleaned up, reconciled.
   @TODO: Turn this into sass map
______________________________________ */
/*************************************************
	Legal configuration variables
  Description: Custom variables for configuring legal theme that are not color based.
**************************************************/
/* Base fonts */
/* Bento fonts */
/* Common
______________________________________ */
/* Component forced heights
   @TODO: consider using ems for text zoom */
/* Focus */
/* Import helpers, these have config variables within them - dependent on tokens*/
/*************************************************
	Font sizes
	Description: Set font-sizes and automatically calculate line-height or set custom.
	Version: 1.0
**************************************************/
/*************************************************
	Typeset
	Description: Custom typography setup for Bento Legal theme
**************************************************/
/* SassScript Map */
/* Mixin */
/* Used for accessing third party components and making them match existing typography guidelines where you can't use utility classes or have control over the markup. */
/*************************************************
	Button mixin for legal theme that generates styles for primary type buttons (primary, danger-primary, icon-primary) based on sass map:
  Example:
  $flagshipname-primary-button: (
    'type': "primary",
    'background': bento-deep-token('color-background-brand-button-primary'),
  );
  @include bento-primary-button($flagshipname-primary-button);
**************************************************/
/* Import placeholders */
/* Secondary buttons have link & form ties */
/* Buttons Disabled States - cannot be modified */
/* Button sizing */
/*************************************************
	Focus placeholders
	Description: Placeholders you can extend for focus styles.
	Version: 1.0

**************************************************/
@media (min-width: 800px) {
  .u-flexGrowCol, .Main .bento-tabset .tab-content,
  .Main .bento-tabset .tab-pane {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
}

.FormMultiselect .bento-multiselect-search .bento-items-label span {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.SVG {
  height: auto;
  width: 100%;
}

@font-face {
  font-family: "Clario";
  src: url(fonts/Clario-Regular.otf);
  font-weight: normal;
}
@font-face {
  font-family: "Clario-Bold";
  src: url(fonts/Clario-Bold.otf);
  font-weight: bold;
}
.SVG--logo {
  min-width: 13.75rem;
}
@media (max-width: 800px) {
  .SVG--logo {
    min-width: none;
  }
}
@media (max-width: 800px) {
  .SVG--logo img {
    max-width: 100%;
  }
}
.SVG--logo .FIlogo {
  vertical-align: -webkit-baseline-middle !important;
}
@media screen and (max-width: 470px) and (min-width: 200px) {
  .SVG--logo .FIlogo {
    display: none !important;
  }
}

.SVG--building {
  max-width: 7rem;
  min-width: 7rem;
}

@media (min-width: 800px) {
  .GlobalLayout,
  .Main,
  .AppLayout {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
}

a, button {
  cursor: pointer !important;
}

.Body {
  /*min-width: rem-calc(240);*/
  overflow-x: hidden;
}

.AppLayout {
  min-height: 100%;
}

@media (min-width: 800px) {
  .GlobalLayout {
    min-height: calc(100% - 3.75rem - 2.75rem);
    overflow: hidden;
  }
}

.GlobalLayout-group {
  border-top: 1px solid #858585;
}
@media (min-width: 800px) {
  .GlobalLayout-group {
    flex-grow: 1;
  }
}

.GlobalLayout-item {
  max-width: 100%;
  padding: 1.25rem;
}

@media (min-width: 800px) {
  .GlobalLayout--twoColumn .GlobalLayout-group {
    display: grid;
    grid-template-areas: "sidebar main";
    grid-template-columns: 21.25rem calc(100% - 21.25rem);
    grid-template-rows: 100%;
  }
}

.GlobalLayout--twoColumn .GlobalLayout-item--small {
  border-top: 1px solid #858585;
  padding: 0;
}
@media (min-width: 800px) {
  .GlobalLayout--twoColumn .GlobalLayout-item--small {
    border-right: 1px solid #858585;
    border-top: 0;
  }
}

.bento-tabset .GlobalLayout-group {
  border-top: 0;
}

.Main-header {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  padding-top: 1.25rem;
}

@media (max-width: 600px) {
  .Main-header .row {
    display: block;
  }
}

.Main-heading {
  flex-grow: 1;
  margin-bottom: 0;
}

.Main-body {
  padding: 1.25rem;
}
@media (min-width: 800px) {
  .Main-body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
}

.Main-body--viewgrid {
  padding: 0rem 1.25rem 0rem 1.25rem;
}
@media (min-width: 800px) {
  .Main-body--viewgrid {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
}

.Main-footer {
  padding: 0 1.25rem 1.25rem;
}

.Main--narrow {
  max-width: 46.25rem;
}

.Main--center {
  margin-left: auto;
  margin-right: auto;
  padding: 1.25rem;
}
@media (min-width: 600px) {
  .Main--center {
    padding-bottom: 3rem;
    padding-top: 3rem;
  }
}

@media (min-width: 600px) {
  .Main--mansory {
    column-gap: 1.25rem;
    display: grid;
    grid-template-columns: 62.8571428571% 34.2857142857%;
    grid-template-rows: auto auto;
    row-gap: 1.25rem;
  }
}

.Main--zeroState {
  height: calc(100vh - 3.75rem);
}

.Main-header--border {
  border-bottom: 1px solid rgb(208, 208, 208);
}

.Main-header--border .Main-heading {
  margin-bottom: 1.25rem;
}

.Main-body--light {
  background-color: rgb(250, 251, 255);
}

.Main-header--summary {
  padding: 0rem 0rem 1.25rem 0rem;
}

.Main-body--NoPadding {
  padding-top: 0;
}

.GlobalLayout--twoColumn .Main-header {
  margin-bottom: 1.25rem;
}

.GlobalLayout--twoColumn .Main-body {
  padding: 0;
}

.MainSection,
.MainSection-listItem {
  margin-bottom: 1.25rem;
}

.MainSection {
  border: 1px solid rgb(175, 175, 175);
}
@media (min-width: 600px) {
  .MainSection {
    margin-bottom: 0;
  }
}
.MainSection:last-child {
  margin-bottom: 0;
}

.MainSection-header {
  background-color: rgb(240, 240, 240);
  border-bottom: 1px solid rgb(175, 175, 175);
}

.MainSection-heading {
  font-weight: weight(semiBold);
  margin-bottom: 0;
}

.MainSection-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.MainSection-listItem:last-child {
  margin-bottom: 0;
}

.MainSection-listDate {
  display: block;
}

.MainSection--small .MainSection-header,
.MainSection--small .MainSection-body,
.MainSection--small .MainSection-footer,
.MainSection--large .MainSection-header,
.MainSection--large .MainSection-body,
.MainSection--large .MainSection-footer {
  padding: 1.25rem;
}

@media (min-width: 600px) {
  .MainSection--small .MainSection-header,
  .MainSection--small .MainSection-body,
  .MainSection--small .MainSection-footer {
    padding: 0.75rem;
  }
}

@media (min-width: 600px) {
  .Main--mansory .MainSection--large {
    grid-row-end: 3;
    grid-row-start: 1;
  }
}

.Section--narrow {
  max-width: 56.25rem;
}

.Section--details {
  margin-top: 2.75rem;
}

.Section--views .Section-header {
  padding-bottom: 0.75rem;
}

.Section--overlap .Section-header {
  margin-bottom: 0;
  padding-bottom: 0;
  position: relative;
}

.Section--views .Section-heading,
.Section--stats .Section-heading,
.Section--details .Section-heading {
  margin-bottom: 0;
}

.Section--dataGrid .Section-body {
  padding-top: 1.25rem;
}

.Section--overflow .Section-body {
  margin-right: -1.25rem;
}
@media (min-width: 1600px) {
  .Section--overflow .Section-body {
    margin-right: 0;
  }
}

.Section--views .Section-body {
  padding-bottom: 0.25rem;
}

.FilterPanel--createView .Section--details {
  margin-top: 1.25rem;
}

.ViewPanel-section--details .Section--details {
  margin-top: 1.5rem;
}

.GlobalLayout .Section-header {
  margin-bottom: 1.5rem;
}

.GlobalLayout-item--large .Section-header,
.GlobalLayout .Section--overlap .Section-header {
  margin-bottom: 0;
}

.FilterPanel--createView .Section--views .Section-heading {
  font-size: 1.25rem;
}

.FilterPanel--createView .Section--details .Section-heading {
  font-size: 1.125rem;
}

.Main .bento-busyloader-blocker {
  max-width: 100% !important;
  width: 100vw !important;
  z-index: 2001;
}

.Main .bento-busyloader-front-blocker {
  z-index: 2000;
}

bento-skip-links {
  z-index: 3000;
}

@media (max-width: 800px) {
  .bui-bento-side-overlay-container-wrapper .bento-side-overlay-container .bento-side-overlay-container-footer {
    border-top-style: none !important;
    height: 10px !important;
    left: initial !important;
    padding: 0 !important;
    right: 10px !important;
    text-align: right !important;
    top: 43px;
  }
}

@media (max-width: 800px) {
  app-bento-multiselect-list .nav-pills > li button {
    padding: 0 !important;
  }
}

@media (max-width: 800px) {
  app-bento-multiselect-list .pills {
    margin: -10px -10px 0 !important;
  }
}

@media (max-width: 800px) {
  .bento-items-label {
    margin-top: 0 !important;
  }
}

app-bento-multiselect-list bento-list {
  min-height: 60px;
}

.padding-top-right {
  padding-left: 1.2rem;
  padding-top: 1.2rem;
}

.padding-left {
  padding-left: 1.2rem;
}

.no-bottom-margin {
  margin-bottom: 0;
}

.no-top-padding {
  padding-top: 0 !important;
}

@media (max-width: 800px) {
  .bento-search {
    width: 70%;
  }
}

.bui-bento-combobox-container bento-list {
  min-height: 80px;
}

.bui-bento-combobox-container-list {
  min-height: 80px;
}

.dm-combo {
  width: 200px;
}

.imgContent {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 30%;
}

.imgDetails {
  padding-left: 2rem;
  width: 70%;
}

.image-icon-body {
  height: 130px;
  width: -webkit-fill-available;
}

.chart-meta {
  padding-top: 0.3rem;
}

.padding-7 {
  padding: 7px;
}

.BalancingDetails {
  padding-bottom: 1.25rem;
}

.create-view-main .Section-header .col {
  padding-left: 0px;
}

.note-info {
  font-size: 16px;
  background-color: #f4f4f4;
  padding: 10px;
}

.save-vs-modal-content h3 {
  width: 90%;
  border-bottom: 0 !important;
}

.save-vs-modal-content .modal-body {
  border-top: solid 1px #afafaf;
}

/*Accessibility color changes*/
bento-toolbar.global-subnav .navbar-nav > li > button.active:before, bento-toolbar.global-subnav .navbar-nav > li > button.router-link-active:before, bento-toolbar.global-subnav .navbar-nav > li > a.active:before, bento-toolbar.global-subnav .navbar-nav > li > a.router-link-active:before, bento-toolbar.global-subnav .navbar-right > li > a.active:before, bento-toolbar.global-subnav .navbar-right > li > a.router-link-active:before, bento-toolbar.global-subnav .navbar-nav > li > [ngbdropdown] > button.active:before, bento-toolbar.global-subnav .navbar-nav > li > [ngbdropdown] > button.router-link-active:before, bento-toolbar.global-subnav .navbar-nav > li > [ngbdropdown] > a.active:before, bento-toolbar.global-subnav .navbar-nav > li > [ngbdropdown] > a.router-link-active:before {
  background-color: #5BA091;
}

bento-side-nav > ul > [bentoSideNavItem] {
  border-bottom-color: #858585;
}

bento-side-nav {
  border-right-color: #858585;
  border-left-color: #858585;
}

.card {
  border: solid 1px #858585;
}

.MainSection-header--slim,
.MainSection--slim .MainSection-header {
  background-color: transparent;
  border-bottom: none;
}

.MainSection-header--slim {
  padding: 1.25rem 1.25rem 0;
}

.MainSection--slim .MainSection-header {
  padding: 1rem 1rem 0;
}

.MainSection--slim .MainSection-body,
.MainSection--slim .MainSection-footer {
  padding: 1rem;
}

bento-multiselect-list .nav-pills > li button:focus span {
  outline: 2px dashed #083899 !important;
}

bento-multiselect-overlay bento-list.cdk-program-focused, bento-multiselect-overlay bento-list.cdk-keyboard-focused {
  outline: none !important;
}

bento-multiselect-overlay bento-list.cdk-program-focused .focused bento-checkbox, bento-multiselect-overlay bento-list.cdk-keyboard-focused .focused bento-checkbox {
  box-shadow: none !important;
  outline: 1px dashed #083899 !important;
  outline-offset: 0 !important;
  z-index: 1;
  outline-style: solid !important;
}

bento-multiselect-list .bento-multiselect-list-item.focused {
  background: #e3e8fa;
}

.modal-footer-visualization.modal-footer {
  flex-direction: initial !important;
  justify-content: flex-end !important;
}

.Header {
  z-index: 1119;
}
@media (max-width: 800px) {
  .Header {
    height: auto;
  }
}

.Header .Header-logo {
  border: 1px solid transparent;
  line-height: 3.375rem;
  margin-left: -0.75rem;
  padding-left: 0.75rem;
}
.Header .Header-logo:hover {
  border-color: rgb(255, 255, 255);
}

@media (max-width: 800px) {
  .Header .topbar-left {
    display: block;
  }
}

@media (max-width: 800px) {
  .Header .topbar-right {
    display: block;
    padding-bottom: 0.75rem;
    padding-left: 0.625rem;
    position: static;
    right: auto;
    width: 100%;
  }
}

.Header .topbar-left .Header-logo {
  padding-right: 0.75rem !important;
}

.Header .Header-item--small {
  margin-right: 0.5rem;
}

.Header .Header-item--medium {
  margin-right: 1.25rem;
}

.Header .topbar-right .Header-item a,
.Header .topbar-right .Header-item button {
  display: inline-flex;
  font-size: 0.875rem;
  height: 2.375rem;
}

.Header .topbar-right .Header-item a {
  border-width: 1px;
}

.Header .topbar-right .btn.dropdown-toggle:active,
.Header .topbar-right .show > .btn.dropdown-toggle {
  background-color: transparent;
  color: rgb(255, 255, 255);
}

.Header .topbar-right .bento-icon-close-circle {
  min-width: 1rem;
}

.Header .topbar-right .btn.dropdown-toggle .btn-text + .bento-icon-caret-down-filled,
.Header .topbar-right .btn.dropdown-toggle .btn-text + .bento-icon-close-circle {
  margin-left: 0.125rem;
}

.HeaderSelectFirm {
  max-width: 5rem;
}

.HeaderSelectFirm-ID {
  width: 100%;
}

.Header .topbar-right .HeaderSelectFirm .form-control {
  font-size: 0.8125rem !important;
}

.Header .dropdown-menu .copyright {
  background-color: rgb(240, 240, 240);
  border-top: 1px solid rgb(175, 175, 175);
  font-size: 0.875rem;
  padding: 0.5rem 1rem;
}

@media (max-width: 800px) {
  .modal-content {
    overflow: auto !important;
  }
}

@media (max-width: 800px) {
  .modal .modal-dialog {
    max-height: 100%;
  }
}

@media (max-width: 800px) {
  .modal .modal-content {
    max-height: 100%;
  }
}

@media (max-width: 800px) {
  .modal-content .modal-header {
    padding: 0;
  }
}

@media (max-width: 800px) {
  .modal-content > .close,
  .modal-content > bento-modal-confirmation > .close {
    top: 0;
  }
}

@media (max-width: 800px) {
  .modal-content .modal-body {
    padding: 1rem;
  }
}

.modal-content > .modal-footer--custom {
  flex-direction: initial;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}

.toolbar-dropdown-toggle::after {
  display: none !important;
}

#selectFirmDropdown ul, #selectFirmDropdown ul li {
  margin: 0px;
  padding: 0px;
  list-style: none;
}

.dropdown-menu li .dropdown-item {
  padding: 0.5rem 1rem;
  font-family: "Clario", Arial, Helvetica, sans-serif !important;
}

.global-header {
  background-color: #3b3f4a;
  background-color: #123015;
}

.global-header .topbar-middle, .global-header .topbar-right {
  background-color: #3b3f4a;
  background-color: #123015;
}

.global-header .topbar-right .btn, .global-header .topbar-right .btn-secondary, .global-header .topbar-right .btn-ghost {
  font-family: "Clario", Arial, Helvetica, sans-serif !important;
}

.HeaderSelectFirm .form-control {
  font-family: "Clario", Arial, Helvetica, sans-serif !important;
}
.HeaderSelectFirm .form-control .bento-list-row.bento-combobox-container-item.bui-bento-combobox-container-item {
  font-family: "Clario", Arial, Helvetica, sans-serif !important;
  font-size: 0.8rem !important;
}

.HeaderSelectFirm.form-control {
  font-family: "Clario", Arial, Helvetica, sans-serif !important;
  font-size: 0.8rem !important;
}
.HeaderSelectFirm.form-control .bento-list-row.bento-combobox-container-item.bui-bento-combobox-container-item {
  font-family: "Clario", Arial, Helvetica, sans-serif !important;
  font-size: 0.8rem !important;
}

ngb-toast.bento-toast-default .close:hover, ngb-toast button.bento-toast-action-btn.btn-outline-primary:hover, .btn-secondary.btn-ghost:hover, .btn-outline-secondary.btn-icon.btn-ghost:hover, .btn-flat-secondary.btn-ghost:hover, .btn-flat-icon.btn-ghost:hover {
  background-color: #4F7B60 !important;
}

bento-side-nav.NavSide {
  border-right: none;
  padding-bottom: 0;
  padding-top: 0;
}

.NavSide ul {
  margin-bottom: 0;
}

bento-side-nav.NavSide > ul {
  border-top: none;
}

.NavSide > ul > li {
  background-color: rgb(255, 255, 255);
}

.NavSide > ul > li > ul {
  padding-top: 0;
}

.NavSide > ul > li > .label {
  padding-left: 1.25rem !important;
}

.NavSide > ul > li > ul > li {
  border-top: 1px solid rgb(175, 175, 175);
  margin-bottom: 0;
}

.NavSide > ul > li > ul > li > .label {
  padding-left: 0 !important;
}

.NavSide > ul > li > ul > li > .label a {
  text-decoration: none;
}

.NavSide > ul > li > ul > li > .label a:hover {
  text-decoration: underline;
}

.NavSide > ul > li > ul > li > .label a > span {
  display: block;
  padding: 1.125rem 0 1.125rem 3.75rem;
}

.NavSide.bui-side-nav-collapsible > ul > li > .label {
  padding-left: 2.25rem !important;
}

.NavSide [bentoSideNavItem] > .label a[aria-current=page] > span::before {
  background-color: rgb(227, 232, 250);
}

.NavSide [bentoSideNavItem] > .label a > span::after,
.NavSide [bentoSideNavItem] > .label a:hover > span::after,
.NavSide [bentoSideNavItem] > .label a[aria-current=location] > span::before {
  background-color: transparent;
}

.NavSide [bentoSideNavItem] > .label a[aria-current=location]:hover > span::before {
  background-color: rgb(237, 239, 245);
}

.NavSide [bentoSideNavItem] > .label a.disabled {
  color: rgb(175, 175, 175);
}

bento-side-nav.NavSide > ul > li[bentoSideNavItem] > div.label a {
  padding-right: 1.25rem;
}

.NavSide li[bentoSideNavItem] div.label a > span {
  display: flex;
}

.NavSide .badge {
  margin-left: auto;
}

.NavSide.bui-side-nav-collapsible li[bentoSideNavItem] div.label a > span::after {
  left: -32px;
}

.NavBreadcrumb {
  margin-bottom: 0.75rem;
}

.bui-bento-combobox-container.bento-combobox-container.bento-append-to.top.in,
.bui-bento-combobox-container.bento-combobox-container.bento-append-to.bottom.in {
  z-index: 3000;
}

bento-toolbar.global-subnav [ngbdropdown] .dropdown-menu.dropdown-menu-right {
  left: -5px;
  right: 0;
}

bento-toolbar.global-subnav .navbar-nav > li > [ngbdropdown] > button {
  line-height: normal;
}

/*Focus border css Start here*/
.NavSide [bentoSideNavItem] > .label a[aria-current=page] > span::before {
  background-color: transparent !important;
}

.NavSide.bui-side-nav-collapsible > ul > li > .label, .NavSide > ul > li > .label {
  padding-left: 0px !important;
  margin-left: 5px !important;
}

bento-side-nav > ul > li[bentoSideNavItem] > div.label a {
  padding: 18px;
}

.NavSide.bui-side-nav-collapsible > ul > li > div.label a {
  padding-left: 2.25rem !important;
}

bento-side-nav > ul > li[bentoSideNavItem] > div.label a:focus, bento-side-nav > ul > li[bentoSideNavItem] > div.label a:focus-visible {
  /*border:solid 2px red;*/
  outline-width: 2px !important;
}

.NavSide [bentoSideNavItem] > .label a[aria-current=page] {
  background-color: rgb(227, 232, 250);
}

.bento-side-nav-branch[bentoSideNavItem] button.btn-expand-collapse:focus span {
  outline: 2px dashed;
}

a:focus, a:focus-visible, bento-toolbar.global-subnav .navbar-nav > li > button:focus,
bento-toolbar.global-subnav .navbar-nav > li > a:focus, bento-toolbar.global-subnav .navbar-right > li > a:focus, bento-toolbar.global-subnav .navbar-nav > li > [ngbdropdown] > button:focus,
bento-toolbar.global-subnav .navbar-nav > li > [ngbdropdown] > a:focus, .cdk-focused, .cdk-keyboard-focused {
  outline-width: 2px !important;
}

.btn i.bento-combobox-dropdown-button-icon {
  position: absolute;
  top: 1px !important;
  bottom: 1px;
  left: 2px;
  right: 2px;
  display: flex;
  justify-content: center;
}
.btn i.bento-combobox-dropdown-button-icon:focus.cdk-keyboard-focused, .btn i.bento-combobox-dropdown-button-icon:focus.cdk-program-focused {
  outline: 2px dashed;
}

bento-toolbar.global-subnav [ngbdropdown] .dropdown-menu button.cdk-program-focused:before, bento-toolbar.global-subnav [ngbdropdown] .dropdown-menu button.cdk-keyboard-focused:before, bento-toolbar.global-subnav [ngbdropdown] .dropdown-menu a.cdk-program-focused:before, bento-toolbar.global-subnav [ngbdropdown] .dropdown-menu a.cdk-keyboard-focused:before {
  border-width: 2px !important;
}

.global-header a:focus, .global-header a:focus-visible, #menu-sorting .dropdown-item.cdk-focused, #menu-sorting .dropdown-item.cdk-keyboard-focused {
  outline-width: 0 !important;
}

ngb-toast.bento-toast-default .cdk-keyboard-focused.close, ngb-toast button.cdk-keyboard-focused.bento-toast-action-btn.btn-outline-primary, .cdk-keyboard-focused.btn-secondary.btn-ghost,
.cdk-keyboard-focused.btn-outline-secondary.btn-icon.btn-ghost,
.cdk-keyboard-focused.btn-flat-secondary.btn-ghost,
.cdk-keyboard-focused.btn-flat-icon.btn-ghost, ngb-toast.bento-toast-default .cdk-program-focused.close, ngb-toast button.cdk-program-focused.bento-toast-action-btn.btn-outline-primary, .cdk-program-focused.btn-secondary.btn-ghost,
.cdk-program-focused.btn-outline-secondary.btn-icon.btn-ghost,
.cdk-program-focused.btn-flat-secondary.btn-ghost,
.cdk-program-focused.btn-flat-icon.btn-ghost {
  border-width: 2px !important;
}

bento-splitter-group .cdk-keyboard-focused.btn.btn-outline-primary.bento-splitter-toggle,
bento-splitter-group .cdk-keyboard-focused.btn.btn-outline-primary.bento-splitter-decrease,
bento-splitter-group .cdk-keyboard-focused.btn.btn-outline-primary.bento-splitter-increase, .cdk-keyboard-focused.btn.btn-outline-secondary:not(.btn-ghost),
.cdk-keyboard-focused.btn-icon.btn-outline-secondary:not(.btn-ghost),
.cdk-keyboard-focused.btn.btn-flat-secondary,
.cdk-keyboard-focused.btn.btn-flat-icon, .bento-toolbar .navbar-nav > li > [ngbdropdown] > .cdk-keyboard-focused[ngbDropdownToggle], .bento-toolbar .navbar-nav .cdk-keyboard-focused.btn-toolbar, ngb-toast .cdk-keyboard-focused.close, bento-scrollable-toolbar .bento-toolbar .bento-scrollable-toolbar-inner bento-scrollable-toolbar-item > button.cdk-keyboard-focused,
bento-scrollable-toolbar .bento-toolbar .bento-scrollable-toolbar-inner bento-scrollable-toolbar-item > [ngbdropdown] > .cdk-keyboard-focused[ngbDropdownToggle], .bento-off-canvas-menu > .bento-off-canvas-menu-aside-wrapper .bento-off-canvas-menu-aside button.cdk-keyboard-focused.bento-off-canvas-menu-close-button,
.bento-off-canvas-menu > .bento-off-canvas-menu-aside-right-wrapper .bento-off-canvas-menu-aside-right button.cdk-keyboard-focused.bento-off-canvas-menu-close-button, .modal-content > .cdk-keyboard-focused.close,
.modal-content > bento-modal-confirmation > .cdk-keyboard-focused.close, .bento-transferbox-wj-dropdown-panel.wj-control.wj-columnfiltereditor button.cdk-keyboard-focused[wj-part=btn-asc], .bento-transferbox-wj-dropdown-panel.wj-control.wj-columnfiltereditor button.cdk-keyboard-focused[wj-part=btn-dsc], bento-transferlist .bento-toolbar .navbar-list > li .cdk-keyboard-focused.btn.transferbox-transfer-button, .bento-toolbar .cdk-keyboard-focused.btn-icon, .bento-splitter-group > .bento-splitter-group-panel > .bento-splitter-handle .cdk-keyboard-focused.btn.btn-primary, .bui-bento-side-overlay-container-wrapper .cdk-keyboard-focused.btn-multiselect-overlay, bento-number-input .cdk-keyboard-focused.bento-number-input-button, bento-combobox .cdk-keyboard-focused.btn-icon, bento-combobox .bui-bento-combobox-footer-item .cdk-keyboard-focused.btn, .cdk-keyboard-focused.btn.btn-outline-primary,
.cdk-keyboard-focused.btn.btn-link, bento-splitter-group .cdk-program-focused.btn.btn-outline-primary.bento-splitter-toggle,
bento-splitter-group .cdk-program-focused.btn.btn-outline-primary.bento-splitter-decrease,
bento-splitter-group .cdk-program-focused.btn.btn-outline-primary.bento-splitter-increase, .cdk-program-focused.btn.btn-outline-secondary:not(.btn-ghost),
.cdk-program-focused.btn-icon.btn-outline-secondary:not(.btn-ghost),
.cdk-program-focused.btn.btn-flat-secondary,
.cdk-program-focused.btn.btn-flat-icon, .bento-toolbar .navbar-nav > li > [ngbdropdown] > .cdk-program-focused[ngbDropdownToggle], .bento-toolbar .navbar-nav .cdk-program-focused.btn-toolbar, ngb-toast .cdk-program-focused.close, bento-scrollable-toolbar .bento-toolbar .bento-scrollable-toolbar-inner bento-scrollable-toolbar-item > button.cdk-program-focused,
bento-scrollable-toolbar .bento-toolbar .bento-scrollable-toolbar-inner bento-scrollable-toolbar-item > [ngbdropdown] > .cdk-program-focused[ngbDropdownToggle], .bento-off-canvas-menu > .bento-off-canvas-menu-aside-wrapper .bento-off-canvas-menu-aside button.cdk-program-focused.bento-off-canvas-menu-close-button,
.bento-off-canvas-menu > .bento-off-canvas-menu-aside-right-wrapper .bento-off-canvas-menu-aside-right button.cdk-program-focused.bento-off-canvas-menu-close-button, .modal-content > .cdk-program-focused.close,
.modal-content > bento-modal-confirmation > .cdk-program-focused.close, .bento-transferbox-wj-dropdown-panel.wj-control.wj-columnfiltereditor button.cdk-program-focused[wj-part=btn-asc], .bento-transferbox-wj-dropdown-panel.wj-control.wj-columnfiltereditor button.cdk-program-focused[wj-part=btn-dsc], bento-transferlist .bento-toolbar .navbar-list > li .cdk-program-focused.btn.transferbox-transfer-button, .bento-toolbar .cdk-program-focused.btn-icon, .bento-splitter-group > .bento-splitter-group-panel > .bento-splitter-handle .cdk-program-focused.btn.btn-primary, .bui-bento-side-overlay-container-wrapper .cdk-program-focused.btn-multiselect-overlay, bento-number-input .cdk-program-focused.bento-number-input-button, bento-combobox .cdk-program-focused.btn-icon, bento-combobox .bui-bento-combobox-footer-item .cdk-program-focused.btn, .cdk-program-focused.btn.btn-outline-primary,
.cdk-program-focused.btn.btn-link {
  border-width: 2px !important;
}

.global-header .topbar-left a:focus {
  box-shadow: none !important;
  outline: 2px dashed #c4c0b5 !important;
  outline-offset: -2px !important;
  z-index: 1;
}

.global-header .topbar-right .dropdown .cdk-keyboard-focused.btn.btn-flat-secondary {
  border: 0px dashed #083899 !important;
}

.dropdown-item:focus:not(.btn-primary)::before {
  border-width: 2px !important;
}

.global-header .topbar-item > a:focus, .global-header .topbar-item > button:focus {
  outline: 2px dashed white;
}

bento-checkbox.cdk-program-focused [aria-checked=true] ~ .bui-checkbox-unchecked, bento-checkbox.cdk-program-focused [aria-checked=mixed] ~ .bui-checkbox-unchecked, bento-checkbox.cdk-keyboard-focused [aria-checked=true] ~ .bui-checkbox-unchecked, bento-checkbox.cdk-keyboard-focused [aria-checked=mixed] ~ .bui-checkbox-unchecked {
  box-shadow: inset 0 0 0 1px #1B1A1A !important;
}

bento-checkbox.cdk-program-focused, bento-checkbox.cdk-keyboard-focused {
  box-shadow: inset 0 0 0 2px #1B1A1A !important;
}

/*bento-checkbox .bui-checkbox-unchecked {
  border-color: #0D0909 !important;
}*/
/*Focus border css Start here*/
bento-toolbar.GlobalSubNav .navbar-nav {
  float: none;
  padding-left: 0.3125rem;
  padding-right: 1rem;
}

bento-toolbar.GlobalSubNav--rightAlignedSpacer .nav > li:nth-child(3) {
  margin-left: auto;
}

bento-toolbar.GlobalSubNav .nav > li > a,
bento-toolbar.GlobalSubNav .nav > li > button,
bento-toolbar.GlobalSubNav .nav > li > div > button,
bento-toolbar.GlobalSubNav .nav > li > div > ul > li > button {
  font-size: 0.875rem !important;
}

bento-toolbar.GlobalSubNav .nav > li > .btn-flat-icon {
  font-size: inherit;
}

bento-toolbar.GlobalSubNav .navbar-nav > li a .bento-smart-badge,
bento-toolbar.GlobalSubNav .navbar-nav bento-scrollable-toolbar-item a .bento-smart-badge {
  left: 0.25rem;
  top: 0rem;
}

/*Branding Change for Global Subnav - Requires ovveride of some base CSS*/
/*Main Global Subnav*/
bento-toolbar.global-subnav {
  background-color: #D64000 !important;
  font-family: "Clario", Arial, Helvetica, sans-serif;
}

/*Remove border from maintheme global subnav*/
bento-toolbar.global-subnav .navbar-nav {
  border: none !important;
}

/*Main Global Subnav Drop down*/
bento-toolbar.global-subnav [ngbdropdown] .dropdown-menu, bento-toolbar.global-subnav [ngbdropdown].dropdown-menu, bento-toolbar.global-subnav bento-megamenu .dropdown-menu, bento-toolbar.global-subnav bento-megamenu.dropdown-menu {
  background-color: #D64000 !important;
}

/*Main Global Subnav Drop down a*/
bento-toolbar.global-subnav [ngbdropdown] .dropdown-menu button, bento-toolbar.global-subnav [ngbdropdown] .dropdown-menu a, bento-toolbar.global-subnav [ngbdropdown].dropdown-menu button, bento-toolbar.global-subnav [ngbdropdown].dropdown-menu a, bento-toolbar.global-subnav bento-megamenu .dropdown-menu button, bento-toolbar.global-subnav bento-megamenu .dropdown-menu a, bento-toolbar.global-subnav bento-megamenu.dropdown-menu button, bento-toolbar.global-subnav bento-megamenu.dropdown-menu a {
  background-color: #D64000 !important;
  color: #fff !important;
}
bento-toolbar.global-subnav [ngbdropdown] .dropdown-menu button:hover, bento-toolbar.global-subnav [ngbdropdown] .dropdown-menu a:hover, bento-toolbar.global-subnav [ngbdropdown].dropdown-menu button:hover, bento-toolbar.global-subnav [ngbdropdown].dropdown-menu a:hover, bento-toolbar.global-subnav bento-megamenu .dropdown-menu button:hover, bento-toolbar.global-subnav bento-megamenu .dropdown-menu a:hover, bento-toolbar.global-subnav bento-megamenu.dropdown-menu button:hover, bento-toolbar.global-subnav bento-megamenu.dropdown-menu a:hover {
  background-color: #802600 !important;
}

/*Main Global Subnav a*/
bento-toolbar.global-subnav .navbar-nav > li > button, bento-toolbar.global-subnav .navbar-nav > li > a, bento-toolbar.global-subnav .navbar-right > li > a, bento-toolbar.global-subnav .navbar-nav > li > [ngbdropdown] > button, bento-toolbar.global-subnav .navbar-nav > li > [ngbdropdown] > a {
  color: #fff !important;
}
bento-toolbar.global-subnav .navbar-nav > li > button:hover, bento-toolbar.global-subnav .navbar-nav > li > button:active, bento-toolbar.global-subnav .navbar-nav > li > button:focus, bento-toolbar.global-subnav .navbar-nav > li > a:hover, bento-toolbar.global-subnav .navbar-nav > li > a:active, bento-toolbar.global-subnav .navbar-nav > li > a:focus, bento-toolbar.global-subnav .navbar-right > li > a:hover, bento-toolbar.global-subnav .navbar-right > li > a:active, bento-toolbar.global-subnav .navbar-right > li > a:focus, bento-toolbar.global-subnav .navbar-nav > li > [ngbdropdown] > button:hover, bento-toolbar.global-subnav .navbar-nav > li > [ngbdropdown] > button:active, bento-toolbar.global-subnav .navbar-nav > li > [ngbdropdown] > button:focus, bento-toolbar.global-subnav .navbar-nav > li > [ngbdropdown] > a:hover, bento-toolbar.global-subnav .navbar-nav > li > [ngbdropdown] > a:active, bento-toolbar.global-subnav .navbar-nav > li > [ngbdropdown] > a:focus {
  background-color: #802600 !important;
  font-weight: 400 !important;
}
bento-toolbar.global-subnav .navbar-nav > li > button.active, bento-toolbar.global-subnav .navbar-nav > li > a.active, bento-toolbar.global-subnav .navbar-right > li > a.active, bento-toolbar.global-subnav .navbar-nav > li > [ngbdropdown] > button.active, bento-toolbar.global-subnav .navbar-nav > li > [ngbdropdown] > a.active {
  background-color: #802600 !important;
  font-weight: 400 !important;
}

/*Removing border from active element*/
bento-toolbar.global-subnav .navbar-nav > li > a.active:before, bento-toolbar.global-subnav .navbar-nav > li > a.router-link-active:before {
  height: 0px;
  background-color: transparent;
}

/*Resources tab*/
bento-toolbar.global-subnav .navbar-right > li > a.active:before, bento-toolbar.global-subnav .navbar-right > li > a.router-link-active:before {
  height: 0px;
  background-color: transparent;
}

.bento-toolbar.global-subnav .navbar-nav > li > button.active:before, bento-toolbar.global-subnav .navbar-nav > li > button.router-link-active:before, bento-toolbar.global-subnav .navbar-nav > li > a.active:before, bento-toolbar.global-subnav .navbar-nav > li > a.router-link-active:before, bento-toolbar.global-subnav .navbar-right > li > a.active:before, bento-toolbar.global-subnav .navbar-right > li > a.router-link-active:before, bento-toolbar.global-subnav .navbar-nav > li > [ngbdropdown] > button.active:before, bento-toolbar.global-subnav .navbar-nav > li > [ngbdropdown] > button.router-link-active:before, bento-toolbar.global-subnav .navbar-nav > li > [ngbdropdown] > a.active:before, bento-toolbar.global-subnav .navbar-nav > li > [ngbdropdown] > a.router-link-active:before {
  background-color: #fff !important;
}

/*Child pages heading font size should match main heading*/
bento-toolbar.GlobalSubNav .nav > li > a, bento-toolbar.GlobalSubNav .nav > li > button, bento-toolbar.GlobalSubNav .nav > li > div > button, bento-toolbar.GlobalSubNav .nav > li > div > ul > li > button {
  font-size: 1rem !important;
}

bento-toolbar.global-subnav .navbar-nav > li > a.router-link-active {
  background-color: #802600 !important;
  font-weight: 400 !important;
}

app-global-sidebar .bento-accordion.accordion > .card .card-header,
app-global-sidebar .bento-accordion.accordion > .card:last-child .card-header button[aria-expanded=false] {
  border-radius: 0;
}

app-global-sidebar .bento-accordion.accordion .card-header button {
  border-color: rgb(153, 153, 153);
  border-left-width: 0;
  border-right-width: 0;
}

app-global-sidebar .bento-accordion.accordion > .card:first-child .card-header button {
  border-bottom-color: rgb(153, 153, 153);
  border-left-width: 0;
  border-radius: 0;
  border-right-width: 0;
  border-top-width: 0;
}

app-global-sidebar .bento-accordion.accordion .card-header button[aria-expanded=true] {
  border-color: rgb(153, 153, 153);
}

app-global-sidebar .bento-accordion.accordion .card .collapse.show {
  border-color: rgb(153, 153, 153);
  border-width: 0;
}

app-global-sidebar .bento-accordion.accordion > .card:last-child .collapse.show {
  border-bottom-width: 1px;
  border-radius: 0;
}

.Main .bento-tabset .nav-tabs {
  border-color: rgb(175, 175, 175);
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.Main .bento-tabset .nav-tabs > .nav-item > .nav-link {
  padding-left: 0;
  padding-right: 0;
}

.Main .bento-tabset .tab-content {
  padding-top: 0;
}

.Section--stats .bento-tabset .nav-tabs {
  margin-bottom: 1.25rem;
}

.DataGrid {
  border: none;
  border-left: 1px solid rgb(175, 175, 175);
  border-radius: 0;
}

.detailGrid {
  border-collapse: collapse !important;
}
.detailGrid .wj-header {
  max-height: 41px !important;
}

.DataGridFlexScroll {
  max-height: 28.125rem;
}

.custom-popup .DataGridFlexScroll {
  max-height: 25rem;
}

.DataGridFlexScroll .wj-cell {
  padding: 8px !important;
}

.ExtractFlexWidth .wj-cell {
  padding: 8px !important;
}

@media screen and (max-width: 4000px) and (min-width: 2500px) {
  .DataGridFlexScroll {
    max-height: 43.125rem;
  }
  .custom-popup .DataGridFlexScroll {
    max-height: 40rem;
  }
}
.DataGrid [wj-part=root] {
  scrollbar-color: rgba(0, 0, 0, 0.4) rgb(240, 240, 240);
  scrollbar-width: thin;
}

.DataGrid [wj-part=root]::-webkit-scrollbar {
  height: 11px;
  width: 11px;
}

.DataGrid [wj-part=root]::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
  border: 2px solid rgb(240, 240, 240);
  border-radius: 6px;
}

.DataGrid [wj-part=root]::-webkit-scrollbar-track {
  background: rgb(240, 240, 240);
}

.DataGrid .wj-cell,
.DataGrid .wj-header {
  border-color: #858585;
}

.DataGrid .wj-cell {
  padding: 1rem;
}

.DataGrid .wj-cell--input {
  padding-bottom: 0.75rem;
  padding-top: 0.75rem;
}

/* Overflow issue of .wj-cell when .wj-state-active is active */
/* The bue border is coming from marquee .wj-marquee which is assigned a z-index of 3 dynamically */
/* Reducing z-index of .wj-marquee prevents down/up arrow scrolling */
/* Targetting sticky/fixed headers globally */
.wj-flexgrid[ng-reflect-sticky-headers=true] div[wj-part=ch], .wj-flexgrid .wj-state-sticky {
  z-index: 4 !important;
}

.wj-flexgrid div[wj-part=ch] {
  z-index: 4 !important;
}

.DataGrid .wj-colheaders .wj-header,
.DataGrid .wj-topleft .wj-cell.wj-header {
  background-color: rgb(245, 247, 255);
}

.DataGrid .wj-colheaders .wj-header {
  border-top: 1px solid #858585;
  font-size: 0.875rem;
  font-weight: 600;
  padding-right: 0.5rem;
}

.DataGrid .wj-frozen-col {
  box-shadow: 1.25rem 0 1.25rem 0 rgba(0, 0, 0, 0.05);
}

.DataGrid .wj-cell a {
  color: rgb(8, 56, 153) !important;
}

.DataGrid .wj-cell a:focus,
.DataGrid .wj-cell a:hover {
  cursor: pointer;
}

.DataGrid .wj-topleft .wj-header {
  border-top: 1px solid rgb(175, 175, 175);
}

.DataGrid .wj-cell.wj-alt,
.DataGrid .wj-rowheaders .wj-cell.wj-header {
  background-color: rgb(255, 255, 255);
}

.DataGrid .wj-topleft .wj-cell.wj-header,
.DataGrid .wj-rowheaders .wj-cell.wj-header {
  border-color: #858585;
}

.DataGrid .wj-topleft .wj-header,
.DataGrid .wj-colheaders .wj-header {
  line-height: 1rem;
  padding-bottom: 0.75rem;
  padding-top: 0.75rem;
}

.DataGrid .wj-cells .wj-cell.wj-state-selected,
.DataGrid .wj-cells .wj-cell.wj-state-multi-selected,
.DataGrid .wj-cells .wj-cell.row-selected,
.DataGrid .wj-cells .wj-cell.wj-state-active {
  background-color: rgb(227, 232, 250);
  color: inherit;
}

.DataGrid--views .wj-cells .wj-cell.wj-state-active {
  background-color: rgb(220, 250, 223);
  color: inherit;
}

.DataGrid--views .wj-topleft .wj-cell.wj-header {
  border: none !important;
  background-color: #FAFBFF !important;
}

.DataGrid--views .wj-rowheaders .wj-cell.wj-header {
  border-color: #858585;
  border: 1px solid #858585;
}

.DataGrid--views [wj-part=tl] {
  border: none;
}

.DataGrid--views .wj-cell.wj-header.wj-state-dragSrc {
  opacity: 0.5 !important;
  width: fit-content !important;
}

.DataGrid .wj-rowheaders .wj-header.wj-state-multi-selected {
  border-width: 1px;
}

.DataGrid--simple [wj-part=rh] {
  overflow: visible !important;
}

.DataGrid--simple .wj-rowheaders .wj-header {
  box-shadow: 1.25rem 0 1.25rem 0 rgba(0, 0, 0, 0.05);
}

.DataGrid--simple .wj-cells .wj-cell.wj-state-active {
  background-color: rgb(220, 250, 223) !important;
  color: inherit;
}

.DataGrid--simple .wj-cell .wj-wrap .wj-state-active {
  background-color: rgb(220, 250, 223) !important;
  color: inherit;
}

.DataGrid--visualization [wj-part=rh] {
  overflow: visible !important;
}

.DataGrid--visualization .wj-rowheaders .wj-header {
  box-shadow: 1.25rem 0 1.25rem 0 rgba(0, 0, 0, 0.05);
}

.DataGrid .wj-row:hover .wj-cell {
  background-color: rgb(237, 239, 245);
}

.DataGrid .wj-row:hover .wj-header {
  background-color: rgb(245, 247, 255);
}

.DataGrid .wj-row:hover .wj-header:hover {
  background-color: rgb(237, 239, 245);
}

.DataGrid--simple .wj-rowheaders .wj-row:first-child .wj-header {
  border-top: 1px solid rgb(175, 175, 175);
  font-size: 0.875rem;
  line-height: 0.875rem;
}

.DataGrid--visualization .wj-rowheaders .wj-header {
  border-top: 1px solid rgb(175, 175, 175);
  font-size: 0.875rem;
  line-height: 0.875rem;
}

.DataGrid--views {
  border-left: none;
}

.DataGrid--views .wj-cells .wj-cell:first-child,
.DataGrid--views .wj-colheaders .wj-row .wj-header:first-child {
  border-left: 1px solid rgb(175, 175, 175);
}

.DataGrid--views .wj-colheaders .wj-row .wj-header:first-child {
  border-left: 1px solid rgb(175, 175, 175);
}

.DataGrid--views .wj-colheaders .wj-row:first-child .wj-header {
  border-bottom: none;
}

.DataGrid--views--pivot .wj-cells .wj-cell:first-child,
.DataGrid--views--pivot .wj-colheaders .wj-row .wj-header:first-child {
  border-left: 1px solid rgb(175, 175, 175);
}

.DataGrid--views--pivot .wj-colheaders .wj-row:first-child .wj-header {
  border-bottom: none;
}

.DataGrid--views--pivot .wj-colheaders .wj-row .wj-header:first-child {
  border-left: 1px solid rgb(175, 175, 175);
}

.DataGrid--views--pivot {
  border-left: none;
}

.DataGrid--views--pivot .wj-colheaders .wj-row:first-child .wj-header:first-child {
  background-color: rgb(255, 255, 255);
  border-left-color: transparent;
  border-top-color: transparent;
}

.DataGrid--simple .wj-cells .wj-row:nth-child(1) .wj-cell {
  border-top: 1px solid rgb(175, 175, 175);
  font-size: 0.875rem;
  line-height: 0.875rem;
}

.DataGrid--visualization .wj-row:nth-child(1) .wj-cells .wj-cell {
  border-top: 1px solid rgb(175, 175, 175);
  font-size: 0.875rem;
  line-height: 0.875rem;
}

.DataGrid bento-checkbox {
  margin-right: 0;
}

.DataGrid [wj-part=cells] .wj-cell .bento-select {
  height: 2rem;
  position: relative;
}

.DataGrid.wj-flexgrid .wj-cell .wj-btn.wj-elem-filter {
  background-color: transparent;
  border-color: transparent;
}

.DataGrid.wj-flexgrid .wj-cell .wj-btn.wj-elem-filter:focus,
.DataGrid.wj-flexgrid .wj-cell .wj-btn.wj-elem-filter:hover {
  border-color: rgb(8, 56, 153);
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.2);
}

.DataGrid.wj-flexgrid .wj-cell .wj-btn.wj-elem-filter .wj-glyph-filter {
  color: rgb(13, 119, 214);
}

.DataGrid.wj-flexgrid .wj-cell .wj-btn.wj-elem-filter .wj-glyph-filter::after {
  content: "\e6a5";
}

.DataGrid-overrideIndicator {
  background-color: rgb(8, 56, 153);
  border-radius: 50%;
  display: inline-block;
  height: 0.25rem;
  margin-bottom: 0.125rem;
  margin-right: 0.5rem;
  width: 0.25rem;
}

.DataGrid .wj-cell--input .DataGrid-status {
  padding-bottom: 0.25rem;
  padding-top: 0.25rem;
}

.DataGrid .wj-cell--input .bento-select > .btn {
  z-index: auto;
}

.hideCell {
  background-color: rgb(255, 255, 255) !important;
  border-left-color: transparent !important;
  border-top-color: transparent !important;
}

.hideCellCreateView {
  border-left-color: transparent !important;
  border-top-color: transparent !important;
}

.topLeft .wj-topleft {
  background-color: white;
}

.checkbox-container {
  align-items: center;
  font-size: 14px;
  margin-top: 1.25rem;
}

.checkbox-label {
  margin-right: 0.625rem;
}

.cell-border {
  align-items: center !important;
  border: 1px solid #d0d0d0 !important;
  color: #193793 !important;
  cursor: all-scroll !important;
  display: flex !important;
  justify-content: center !important;
}

.wj-state-dragsrc {
  width: fit-content !important;
}

.FilterPanel--createView .DataGrid,
.ViewPanel-section--details .DataGrid,
.FilterPanel--createView .DataGrid--views .wj-colheaders .wj-row:first-child .wj-header:first-child,
.ViewPanel-section--details .DataGrid--views .wj-colheaders .wj-row:first-child .wj-header:first-child {
  background-color: rgb(250, 251, 255);
}

.FilterPanel--visualization {
  padding: 1.25rem;
}

.FilterPanel--createView .DataGrid--views--pivot .wj-colheaders .wj-row:first-child .wj-header:first-child,
.FilterPanel--createView .DataGrid--views--pivot .wj-colheaders .wj-row:first-child .wj-header:nth-child(2),
.FilterPanel--createView .DataGrid--views--pivot .wj-colheaders .wj-row:first-child .wj-header:nth-child(3),
.ViewPanel-section--details .DataGrid--views--pivot .wj-colheaders .wj-row:first-child .wj-header:first-child,
.ViewPanel-section--details .DataGrid--views--pivot .wj-colheaders .wj-row:first-child .wj-header:nth-child(2),
.ViewPanel-section--details .DataGrid--views--pivot .wj-colheaders .wj-row:first-child .wj-header:nth-child(3) {
  background-color: rgb(250, 251, 255);
}

.cdk-visually-hidden {
  display: none !important;
}

.fms-input {
  width: 20rem;
}

.extractDiv {
  padding: 20px;
  padding-left: 0;
  padding-top: 20px;
}

.extractGrid {
  border-bottom: none;
  border-right: none;
  border-top: none;
  width: fit-content;
}

.glEditError {
  color: #8f221a;
}

.manageFirmInfoDiv {
  width: 45%;
}

.manageFirmPeerMonitorDiv {
  margin-left: 70px;
}

.divFlex {
  display: flex;
}

.manageFirmDiv {
  margin-top: -10px;
  width: 45%;
}

.manageFirmLabel {
  margin-top: 13px;
}

.varianceAuthorizeExtractButton {
  padding: 5px 50px;
}

.width50 {
  width: 50%;
}

.textDecorationNone {
  text-decoration: none;
}

.viewDataGrid {
  margin-top: 20px;
}

.breadCrumbColor {
  color: #083899;
  cursor: pointer;
}

.fontXXLarge {
  font-size: xx-large;
}

.custom-peer-groups-list .wj-header.wj-align-center {
  text-align: left !important;
}

.wj-flexgrid .wj-state-sticky .wj-header {
  opacity: unset !important;
}

.Chart-Datagrid .wj-cell[role=rowheader] {
  pointer-events: none !important;
}

.viewDataGrid .DataGrid .wj-colheaders .wj-header, .viewDataGrid .DataGrid .wj-row .wj-cell, .viewDataGrid .DataGrid .wj-row .wj-cell a {
  letter-spacing: 0 !important;
  word-spacing: 0 !important;
}

@media (min-width: 800px) {
  .Actions {
    align-items: center;
    display: flex;
  }
}

.Actions-flex {
  display: flex;
}
@media (max-width: 800px) {
  .Actions-flex {
    align-content: flex-end;
    flex-flow: column wrap;
  }
}

.Actions-item-flex-end {
  margin-top: 0;
}

.chart-visualization .Actions {
  display: flex;
  align-items: center;
}

.chart-visualization .Actions-item {
  margin-top: 0 !important;
  margin-left: 1.25rem !important;
}

.Actions-item {
  margin-top: 1.25rem;
}
.Actions-item:first-child {
  margin-top: 0;
}
@media (min-width: 800px) {
  .Actions-item {
    margin-left: 1.25rem;
    margin-top: 0;
  }
  .Actions-item:first-child {
    margin-left: 0;
  }
}

.Actions-flex > .Actions-item-flex {
  align-items: center;
  padding-top: 0;
}

.Actions-item--indicator {
  font: 400 1rem / 1.5rem "Source Sans Pro", Arial, sans-serif;
  max-height: 1.5rem;
  padding-top: 0.125rem;
}

.Actions-item--indicator .bento-icon-star {
  color: rgb(8, 56, 153);
}

.Actions-button,
.Actions-button > .bento-busyloader-blocker {
  display: inline-flex;
}

.Actions-button > .bento-busyloader-blocker {
  margin: -0.125rem -0.125rem -0.125rem 0.75rem !important;
  position: static;
  transform: translate(0, 0);
}

.Actions-dropDownButton {
  min-width: 8.75rem;
}

.Actions-dropDownButton .btn-text {
  flex-grow: 1;
}

.Actions-sortButton {
  align-items: center;
  display: flex;
}

.Actions-item .btn .bento-icon-filter-sorting-down {
  margin-top: 0.125rem;
}

.Actions-item .btn.btn-icon .bento-icon-caret-up-filled,
.Actions-item .btn.btn-icon .bento-icon-caret-down-filled {
  margin-left: 1rem;
}

@media (min-width: 800px) {
  .Actions-item--small {
    margin-left: 0.5rem;
  }
  .Actions-item--small:first-child {
    margin-left: 0;
  }
}

@media (min-width: 800px) {
  .Actions-item--large {
    margin-left: 1.75rem;
  }
  .Actions-item--large:first-child {
    margin-left: 0;
  }
}

.Section-footer .Actions {
  margin-top: 1.25rem;
}

.Section--overlap .Actions {
  left: 0;
  top: 0;
  z-index: 5;
}

@media (min-width: 800px) {
  .card .Actions-item {
    margin-left: 0.5rem;
  }
}

.Actions-view-data {
  display: inline-flex;
  justify-content: space-between;
  width: 60px;
}

.Actions-item-view-data {
  margin-left: 0;
  margin-top: 0 !important;
}

.tooltip-shown {
  width: max-content;
}

@media (max-width: 600px) {
  .timeKeepers .tooltip-shown {
    width: 140px;
    left: -156px !important;
  }
}

.tooltip {
  z-index: 10 !important;
}

.transfer bento-checkbox {
  overflow: unset !important;
}

.cursor-pointer {
  cursor: pointer;
}

.max-width-100 {
  max-width: 100px !important;
}

.freez-unfreez-actions-main {
  position: relative;
  display: inline;
}

@media (max-width: 600px) {
  .dropdown-menu[x-placement^=top], .dropdown-menu[x-placement^=right], .dropdown-menu[x-placement^=bottom], .dropdown-menu[x-placement^=left] {
    left: 34px !important;
    width: 192px;
  }
}

@media (max-width: 600px) {
  .popover {
    font-size: 7px !important;
  }
}

@media (max-width: 600px) {
  .popover-header {
    font-size: 8px !important;
  }
}

.Form-set {
  margin-bottom: 0.5rem;
}

.Form-legend {
  margin-bottom: 0;
}

.FormSet-heading,
.Form-fieldset .Form-legend {
  margin-bottom: 0.25rem;
}

.FormSet .form-check {
  padding-left: 0;
}

.Form-fieldset--divider {
  border-bottom: 1px solid rgb(175, 175, 175);
  margin-bottom: 0.75rem;
  padding-bottom: 0.75rem;
}
.Form-fieldset--divider:last-child {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}

.Form-fieldset .form-check-input {
  margin-left: 0;
  margin-right: 0.5rem;
  position: relative;
}

.FormPrefix {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
}

.FormPrefix-name {
  color: rgb(102, 102, 102);
  font-size: 0.875rem;
  margin-right: 0.25rem;
  min-width: 2rem;
  white-space: nowrap;
}

.FormPrefix .form-control {
  width: calc(100% - 2.25rem);
}

.FormPrefix bfm-error-container {
  width: 100%;
}

.Form-input {
  max-width: 100%;
  position: relative;
}

.Form-input--extraSmall {
  max-width: 6.25rem;
}

.Form-input--small {
  max-width: calc(20.625rem / 2 - 0.75rem);
}

.Form-input--medium {
  max-width: 20.625rem;
}

.Form-input--large {
  max-width: 28.125rem;
}

.Form-input .form-control[disabled] {
  background-color: rgb(240, 240, 240);
  color: rgb(102, 102, 102);
}

.Form-input.row {
  margin-left: -0.75rem;
  margin-right: -0.75rem;
  max-width: calc(100% + calc(0.75rem + 0.75rem));
}

.Form-input--medium.row {
  max-width: calc(20.625rem + calc(0.75rem + 0.75rem));
}

.Form-input--large.row {
  max-width: calc(28.125rem + calc(0.75rem + 0.75rem));
}

.Form-input.row .col {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.Form-input .bento-select {
  right: auto;
}

.Actions .bento-search .form-control {
  min-width: 13.75rem;
}

.Form-group--indent {
  padding-left: calc(0.75rem + 0.75rem);
}

.Form-group--indent .Form-input {
  max-width: calc(100% - calc(0.75rem + 0.75rem));
}

.Form-group--indent .Form-input--extraSmall {
  max-width: calc(6.25rem - calc(0.75rem + 0.75rem));
}

.Form-group--indent .Form-input--small {
  max-width: calc(calc(20.625rem / 2 - 0.75rem) - calc(0.75rem + 0.75rem));
}

.Form-group--indent .Form-input--medium {
  max-width: calc(20.625rem - calc(0.75rem + 0.75rem));
}

.Form-group--indent .Form-input--large {
  max-width: calc(28.125rem - calc(0.75rem + 0.75rem));
}

.Form-checkbox {
  margin-bottom: 0.25rem;
}
.Form-checkbox:last-child {
  margin-bottom: 1rem;
}

.Form-combobox .bento-list-row {
  height: auto;
  line-height: normal;
}

.Form-combobox .bui-bento-combobox-container-item.bento-list-row > div {
  line-height: normal;
  overflow: visible;
  text-overflow: unset;
  white-space: normal;
}

.FormRange-group {
  display: flex;
}
@media (max-width: 600px) {
  .FormRange-group {
    display: block;
  }
}

.FormRange-item {
  flex-grow: 1;
  margin-left: 0.5rem;
}
.FormRange-item:first-child {
  margin-left: 0;
}

.FormRange-item--mediator,
.FormRange-item--action {
  align-items: flex-end;
  display: inline-flex;
  flex-grow: 0;
}

.FormRange-item--mediator {
  justify-content: center;
  margin-bottom: 0.25rem;
}

.FormRange-item--action {
  margin-bottom: 1rem;
}

.FormRange-item--input {
  margin-left: 1.25rem;
  max-width: 8.125rem;
}
.FormRange-item--input:first-child {
  margin-left: 0;
}

.FormRange-item--select,
.FormRange-item--override {
  max-width: 8.75rem;
}

.FormRange-item--override {
  margin-left: 2.75rem;
}

@media (max-width: 600px) {
  .timeKeepers .FilterPanel--toggle .FilterPanel-listItem {
    width: 80% !important;
  }
}
@media (max-width: 290px) {
  .timeKeepers .FilterPanel--toggle .FilterPanel-data {
    max-width: 100% !important;
    width: 100% !important;
  }
}
@media (max-width: 600px) {
  .timeKeepers .FilterPanel-footer--Modal {
    justify-content: flex-start;
  }
}

.FormMultiselect,
.FormMultiselect .bento-multiselect-overlay-inner,
.FormMultiselect .bento-side-overlay,
.FormMultiselect .bento-side-overlay .bento-side-overlay-toggle,
.FormMultiselect .bento-side-overlay.open .bento-side-overlay-container-wrapper {
  display: block;
}

.FormMultiselect .bento-multiselect-overlay-inner {
  line-height: normal;
}

.FormMultiselect .bento-side-overlay {
  float: none;
}

.FormMultiselect .bento-side-overlay .btn.bento-side-overlay-toggle {
  color: inherit;
  font-weight: 400;
  min-height: 2rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  text-align: left;
  width: 100%;
}
.FormMultiselect .bento-side-overlay .btn.bento-side-overlay-toggle:hover {
  box-shadow: none;
}
.FormMultiselect .bento-side-overlay .btn.bento-side-overlay-toggle[disabled] {
  background-color: rgb(250, 250, 250);
  border-color: rgb(175, 175, 175);
  color: rgb(175, 175, 175);
  cursor: not-allowed;
  user-select: none;
}

.FormMultiselect .bento-multiselect-overlay-inner > .bento-multiselect-overlay-label {
  display: none;
}

.FormMultiselect .bento-side-overlay.bento-side-overlay-right .bento-side-overlay-container-wrapper {
  margin-left: 0;
  position: relative;
}

.FormMultiselect .bento-side-overlay .bento-side-overlay-left-arrow {
  display: none;
}

.FormMultiselect .bui-bento-side-overlay-container-wrapper .bento-side-overlay-container {
  height: auto;
}

.FormMultiselect .bui-bento-side-overlay-container-wrapper.show-overlay .bento-side-overlay-container {
  margin-bottom: 0.75rem;
  position: static;
}

.FormMultiselect .bui-bento-side-overlay-container-wrapper.show-overlay .bento-side-overlay-container,
.FormMultiselect bento-multiselect-list,
.FormMultiselect app-bento-multiselect-list {
  min-width: auto;
}

.FormMultiselect bento-multiselect-list .bento-list-row,
.FormMultiselect app-bento-multiselect-list .bento-list-row {
  height: auto;
  line-height: 1rem;
  padding-bottom: 0.75rem;
  padding-top: 0.75rem;
}

.FormMultiselect bento-multiselect-list .bento-multiselect-list-item,
.FormMultiselect app-bento-multiselect-list .bento-multiselect-list-item {
  overflow: visible;
  text-overflow: clip;
  white-space: nowrap;
}

.FormMultiselect bento-multiselect-list .nav-pills button,
.FormMultiselect app-bento-multiselect-list .nav-pills button,
.FormMultiselect bento-multiselect-list .bento-search-label,
.FormMultiselect app-bento-multiselect-list .bento-search-label {
  text-transform: lowercase;
}
.FormMultiselect bento-multiselect-list .nav-pills button::first-letter,
.FormMultiselect app-bento-multiselect-list .nav-pills button::first-letter,
.FormMultiselect bento-multiselect-list .bento-search-label::first-letter,
.FormMultiselect app-bento-multiselect-list .bento-search-label::first-letter {
  text-transform: capitalize;
}

.FormMultiselect .bento-reset-close-button .bento-icon-close-x {
  top: -1px;
}

.FormMultiselect .bui-bento-side-overlay-container-wrapper .bento-side-overlay-container .bento-side-overlay-container-footer {
  text-align: left;
}

.FormMultiselect--extend .bui-bento-side-overlay-container-wrapper.show-overlay .bento-side-overlay-container,
.FormMultiselect--extend bento-multiselect-list,
.FormMultiselect--extend app-bento-multiselect-list {
  min-width: 30.625rem;
}

.FormMultiselect--extendSmall .bui-bento-side-overlay-container-wrapper.show-overlay .bento-side-overlay-container,
.FormMultiselect--extendSmall bento-multiselect-list,
.FormMultiselect--extendSmall app-bento-multiselect-list {
  min-width: 20.625rem;
}

.modal-dialog .FormMultiselect .bento-side-overlay.open {
  min-height: 23.625rem;
}

.FormInputGroup .input-group-append > .input-group-text {
  background-color: rgb(255, 255, 255);
  border-bottom-right-radius: 2px;
  border-color: rgb(64, 64, 64);
  border-top-right-radius: 2px;
  color: rgb(38, 38, 38);
  display: inline-flex;
  font-weight: 600;
  justify-content: center;
  min-width: 2rem;
  padding: 0 0.5rem;
}

.FormTags.form-control {
  border: none;
}

.label-required-summary::before {
  content: "*";
  font-weight: bold;
  margin-right: 0.3125rem;
}

.Transferbox-header .col {
  min-width: 20.25rem;
}

.OrderedList {
  margin-bottom: 0;
  padding-left: 1.75rem;
}

.OrderedList li {
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
}

.PeerMeta {
  margin-bottom: 0;
}

.PhoneMeta {
  max-width: 13.75rem;
}

.UserMeta {
  margin-bottom: 0;
  margin-top: 1.25rem;
  max-width: 18.75rem;
}

@media (min-width: 800px) {
  .PeerMeta dt {
    margin-top: 0;
  }
}

.FirmDetails.bento-desc-list dt,
.FirmDetails.bento-desc-list dd {
  font-size: 0.875rem;
}

.PeerMeta.bento-desc-list dt {
  line-height: 1.5rem;
}

@media (min-width: 800px) {
  .PhoneMeta.bento-desc-list dd {
    margin-bottom: 0;
  }
}

@media (min-width: 800px) {
  .PhoneMeta.bento-desc-list dt,
  .PhoneMeta.bento-desc-list dd {
    margin-top: 0;
  }
}

.PeerMeta.bento-desc-list--inline dt,
.PeerMeta.bento-desc-list--inline dd {
  display: inline;
}

.PeerMeta--small.bento-desc-list dt,
.PeerMeta--small.bento-desc-list dd {
  font-size: 0.875rem;
}

.PeerMeta.bento-desc-list--inline .dtCss {
  font-weight: 600;
}

.PeerMeta.bento-desc-list--inline .ddCss {
  margin: 0rem 0rem 0rem 0.1875rem;
}
@media (min-width: 800px) {
  .PeerMeta.bento-desc-list--inline .ddCss {
    margin: 0rem 0rem 0rem 0.25rem;
  }
}

.PeerMeta.bento-desc-list--inline .bento-desc-list-item {
  float: none;
  padding: 0;
}

@media (min-width: 800px) {
  .PhoneMeta.bento-desc-list dt + dd:not(:first-of-type) {
    margin-top: 0;
  }
}

.chart-list-type {
  list-style-type: none;
}

.Card {
  margin-top: 1.25rem;
}
.Card:first-child {
  margin-top: 0;
}

.Card-header {
  background-color: rgb(250, 251, 255);
  border: 1px solid rgb(175, 175, 175);
  padding: 0.75rem 1rem;
}

.Card-heading {
  margin-bottom: 0;
}

.Card-body {
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(175, 175, 175);
  border-top: none;
  margin-top: -1px;
  padding: 0 0.75rem 1rem;
}

.Card-body.collapse {
  display: none;
}

.Card-body.collapse.show {
  background-color: rgb(250, 251, 255);
  display: block;
}

.Card-body.collapse.show.bento-is-busy {
  min-height: 5rem;
}

.Card-details {
  border-top: 1px solid rgb(175, 175, 175);
  margin-left: 1rem;
  margin-right: 1rem;
  padding-bottom: 1rem;
  padding-top: 1rem;
}

.Card-group {
  display: flex;
}

.Card-item--large {
  width: 70%;
}

.Card-item--small {
  padding-left: 1.25rem;
  width: 30%;
}

.Card .card-body {
  padding: 1rem;
}

.Section--views .Card {
  margin-bottom: 0.75rem;
}

.Section--views .Card-title {
  font: 400 1.125rem / 1.75rem "Source Sans Pro", Arial, sans-serif;
  font-weight: 600;
  margin-bottom: 0;
}

.FilterPanel--createView .Card-heading {
  font-size: 1rem;
}

.Chart-Area {
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;
}

.Chart {
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(175, 175, 175);
  height: 100%;
  margin: 0;
  padding: 1.25rem 0 1.25rem 1.25rem;
  width: 100%;
}

.Chart ellipse {
  rx: 3;
  ry: 3;
  stroke-width: 1;
}

.Chart ellipse:hover {
  rx: 4;
  ry: 4;
  stroke-opacity: 0.3;
  stroke-width: 17;
}

.Chart polyline {
  stroke-width: 1;
}

.wj-flexchart {
  font-family: Arial, sans-serif;
  height: 520px;
  width: 100%;
}

.wj-flexchart .wj-axis-x .wj-title,
.wj-flexchart .wj-axis-y .wj-title,
.wj-flexchart .wj-label {
  font-size: 0.8125rem;
}

.wj-flexchart .wj-legend > g .wj-label {
  letter-spacing: 0 !important;
  word-spacing: 0 !important;
}

.Visualization .wj-gridline {
  opacity: 0.2;
}

.Visualization .wj-gridline-minor {
  opacity: 0.5;
}

.Visualization .wj-plot-area rect {
  stroke: #666;
  stroke-opacity: 0.5;
}

.Chart .wj-axis-x .wj-line,
.Chart .wj-axis-y .wj-gridline {
  stroke: rgb(38, 38, 38);
  stroke-width: 1;
  transform: translateX(-0.625rem);
}

.Chart .wj-axis-y .wj-gridline {
  opacity: 1;
  stroke-width: 0.5;
}

.Chart .wj-axis-x .wj-gridline,
.Chart .wj-axis-y .wj-gridline-minor {
  opacity: 1;
  stroke: rgb(208, 208, 208);
  stroke-width: 1;
}

.Chart .wj-axis-y .wj-gridline-minor {
  transform: translateX(-0.625rem);
}

.Chart .wj-axis-x .wj-title,
.Chart .wj-axis-y .wj-title,
.Chart .wj-legend .wj-title {
  font-style: 400;
}

.Chart .wj-axis-x .wj-title,
.Chart .wj-axis-y .wj-title,
.Chart .wj-label {
  font-size: 0.875rem;
  width: fit-content;
}

.wj-tooltip {
  background-color: rgb(38, 38, 38);
  border-radius: 2px;
  color: rgb(255, 255, 255);
  font-size: 0.875rem;
  line-height: 1.5rem;
  padding: 8px;
  text-align: center;
}

.wj-tooltip::after {
  display: none;
}

.wj-data-label {
  background: #fff;
  font-size: 11px;
  font-weight: 600;
  padding: 2px;
  padding-bottom: 0;
}

.one-offs .wj-control:focus,
.one-offs .wj-control :focus,
.one-offs .wj-control a:focus {
  outline: 1px dashed #083899 !important;
  outline-width: 2px !important;
}

.wj-control:focus,
.wj-control :focus,
.wj-control a:focus {
  outline: black dashed 1px !important;
}

#cc-resetBtn-DPA:focus-visible,
#cc-resetBtn-KPM:focus-visible,
#cc-resetBtn-KPMD:focus-visible,
#cc-resetBtn-DPA:focus,
#cc-resetBtn-KPM:focus,
#cc-resetBtn-KPMD:focus {
  outline: 2px dashed #000 !important;
}

.chart-reset-button {
  background: transparent !important;
  border: transparent !important;
}

.chart-list-type {
  list-style-type: none !important;
  padding-inline-start: 0 !important;
}

.chart-req-reset-sec {
  display: block;
  padding-left: 1.25rem;
  padding-bottom: 0.5rem;
}

.chart-req-reset-sec .chart-reset-button {
  float: right;
  position: relative;
  top: -5px;
}

.modal-content .modal-footer.modal-common-action-btns-footer {
  flex-direction: unset;
  justify-content: flex-end !important;
}

.modal-content .modal-footer.modal-common-action-btns-footer .btn.btn-outline-secondary {
  margin-right: 0.25rem !important;
}

.modal-content .modal-footer.modal-common-action-btns-footer .btn.btn-primary {
  margin-right: 0 !important;
}

.AspectRatio {
  position: relative;
  width: 100%;
}

.AspectRatio-target {
  position: absolute;
  top: 0;
}

.AspectRatio--1x1 {
  padding-top: 100%;
}

.AspectRatio--3x2 {
  padding-top: 66.67%;
}

.AspectRatio--16x9 {
  padding-top: 56.25%;
}

.AspectRatio--21x9 {
  padding-top: 42.86%;
}

.FilterPanel {
  border-top: 1px solid rgb(208, 208, 208);
  display: flex;
  flex-direction: row;
  padding: 0;
  width: 100%;
}

.FilterPanel-control {
  background-color: rgb(255, 255, 255);
  border-right: 1px solid rgb(208, 208, 208);
  flex-grow: 1;
  max-width: 20rem;
  min-width: 20rem;
}

.FilterPanel-control--Modal {
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.2);
  display: none;
  max-width: 20rem;
  min-width: 20rem;
}

.FilterPanel-control--Modal.is-open {
  align-items: baseline;
  display: block;
  height: fit-content;
  overflow: visible;
  position: absolute;
  top: 6.7rem;
  z-index: 1200 !important;
}
@media (max-width: 600px) {
  .FilterPanel-control--Modal.is-open {
    top: 11.1rem;
  }
}
@media only screen and (max-width: 400px) {
  .FilterPanel-control--Modal.is-open {
    top: 13.8rem;
  }
}

.FilterPanel-control-scroll.FilterPanel-control--Modal.is-open {
  overflow: hidden;
}

.FilterPanel-data .row {
  margin-left: 0;
}

.Filter-buttons {
  margin-top: 12px;
}

.Filter-buttons > button {
  margin-right: 0.625rem;
}

.FilterPanel-control--Timekeeper {
  width: 100%;
}

.FilterPanel-footer--Modal {
  align-items: center;
  background: #f5f7ff;
  border: 1px solid #d0d0d0;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  padding: 0.75rem;
}

.FilterPanel-footer--Modal > button {
  margin-left: 0.75rem;
}

.btn-clear-filter {
  background-color: transparent;
  border: none;
  border-radius: 2px;
  border-style: solid;
  border-width: 1px;
  color: #083899;
  cursor: default;
  display: inline-block;
  font-family: "Source Sans Pro", Arial, sans-serif;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.25rem;
  padding: 0.3125rem 0.9375rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  vertical-align: middle;
  white-space: normal;
}
.btn-clear-filter:hover {
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.2);
  color: #083899;
}
.btn-clear-filter:disabled {
  border: none;
  color: silver;
  cursor: none;
  pointer-events: none;
  user-select: none;
}

.FilterPanel-header {
  padding: 1rem 1.25rem 0;
  padding-left: 1.2rem !important;
}

.FilterPanel-description {
  margin-bottom: 0;
  padding-left: 1.2rem !important;
}

.axis {
  margin-left: 20px;
}

.FilterPanel-body {
  padding-bottom: 1rem;
}

.FilterPanel-body .form-group {
  margin-bottom: 0;
  padding: 0.25rem 1.25rem;
}

.FilterPanel-body .Form-group--indent {
  padding-left: 2.5rem;
}

.FilterPanel-body bento-combobox.bento-select input {
  padding-left: 0.75rem;
}

.FilterPanel-footer {
  padding: 1rem 1.25rem;
}

.FilterPanel-dataGrid {
  padding: 1.25rem;
}

.FilterPanel--toggle {
  overflow: hidden;
  transform: translateX(-21rem);
  transition: transform 0.2s, width 0.2s;
  width: calc(100% + 21rem);
}

.FilterPanel--toggle--Modal {
  border: none;
  overflow: visible;
  transform: none;
  transition: transform 0.2s, width 0.2s;
  width: 100%;
}

.FilterPanel--toggle.is-open {
  transform: translateX(0);
  width: 100%;
}

.FilterPanel--toggle--Modal.is-open {
  display: block;
  transform: translateX(0);
  width: 100%;
}

.FilterPanel--toggle .FilterPanel-control {
  max-width: 25rem;
}

.FilterPanel--toggle .FilterPanel-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 0.75rem;
  position: relative;
}

.FilterPanel--toggle--Modal .FilterPanel-header {
  border-bottom: 1px solid rgb(208, 208, 208);
}

.FilterPanel--toggle .FilterPanel-heading {
  margin-bottom: 0;
  padding: 0.25rem;
}

.FilterPanel--toggle .CreatView-FilterPanel-heading {
  display: flex;
  justify-content: space-between;
  padding: 0;
}

.FilterPanel--toggle .FilterPanel-body {
  padding-top: 1rem;
}

.FilterPanel--toggle .FilterPanel-list {
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
}

.FilterPanel--toggle .FilterPanel-listItem {
  display: flex;
  padding: 0.5rem 1.25rem;
}

.FilterPanel--toggle .FilterPanel-display,
.FilterPanel--toggle .FilterPanel-display-checkbox,
.FilterPanel--toggle .FilterPanel-input {
  flex-grow: 1;
}

.FilterPanel--toggle .FilterPanel-display {
  margin-top: 1.5rem;
  max-width: 2rem;
}

.FilterPanel--toggle .FilterPanel-display-checkbox {
  max-width: 2rem;
}

.FilterPanel--toggle .FilterPanel-data {
  width: calc(100% - 25rem);
}

.FilterPanel--toggle--Modal .FilterPanel-data {
  width: 100%;
}

.FilterPanel--createView .FilterPanel-data {
  flex-grow: 1;
  max-width: calc(100% - 20rem);
  min-width: calc(100% - 20rem);
}

.FilterPanel--createView .ViewPanel-section--primary {
  margin-bottom: 0;
}

.FilterPanel--createView .FilterPanel-grid > .bento-busyloader-blocker {
  bottom: auto;
  height: calc(100vh - 3.75rem - 2.75rem);
}

.FilterPanel-separator {
  margin-bottom: 0;
  padding: 0.3125rem 0 0.3125rem 0;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.chart-container {
  height: 100%;
  overflow-y: auto;
}

.chart-filter-container {
  height: 100% !important;
  overflow-y: auto !important;
}

.mr-10 {
  margin-right: 10px;
}

.resetButton {
  color: #005da2;
  cursor: pointer;
  padding: 0.25rem 1.25rem;
}

.footer {
  align-items: center;
  background-color: white;
  border-top: 1px solid grey;
  bottom: 0;
  display: flex;
  height: 100%;
  justify-content: center;
  padding-top: 10px;
  position: sticky;
  width: 100%;
  z-index: 100;
}

.asideGl {
  height: fit-content !important;
  overflow-y: auto !important;
}

.asideClass {
  position: fixed !important;
  top: 0 !important;
}

.divClass {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
}

.btnClass {
  width: 45%;
}

.mainClass {
  height: 81%;
  position: absolute;
}

.bento-reset-close-button-custom {
  position: absolute;
  border: none;
  top: 2px;
  right: 5px;
  z-index: 99;
  background: none;
  color: #083899;
  padding: 0;
  width: 28px;
  height: 28px;
  text-align: center;
}

.bento-reset-close-button-custom i {
  top: -1px;
}

.bento-reset-close-button-custom:focus {
  border: 1px dashed #083899;
  outline-offset: 0px;
  outline: none;
  border-radius: 2px;
}

.bento-search input:focus {
  outline-width: 2px;
}

.FilterPanel-control--Timekeeper .FilterPanel-header .btn:focus {
  border: 2px dashed #083899;
  outline-offset: 0px;
  border-radius: 2px;
}

.ViewPanel-section--primary {
  margin-bottom: 2.75rem;
}

.ViewPanel-header {
  margin-bottom: 1.25rem;
}

.ViewPanel-section--primary .Section-body {
  padding-bottom: 0;
}

.ViewPanel-section .cord-body.collapse {
  display: none;
}

.FilterPanel--createView .ViewPanel-section {
  padding: 1.25rem;
}

.FilterPanel--createView .ViewPanel-section--primary {
  background-color: rgb(250, 251, 255);
  border-bottom: 1px solid rgb(208, 208, 208);
}

.ZeroState {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  text-align: center;
}

.ZeroState-header {
  margin-bottom: 0.5rem;
  max-height: 6rem;
  width: auto;
}

.ZeroState-header svg {
  height: 100%;
  width: 100%;
}

.ZeroState-body {
  font-weight: 400;
  max-width: 25rem;
}

.ZeroState-actions {
  border-top: 1px solid rgb(208, 208, 208);
  padding-top: 1rem;
}

.ZeroState-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.ZeroState-item {
  margin-bottom: 1rem;
}
.ZeroState-item:last-child {
  margin-bottom: 0;
}

@media (min-width: 1200px) {
  .staffing-ratio-page bento-alert .alert-container {
    width: 50% !important;
  }
}
#srfileuplod {
  display: none;
}

.paneDesign {
  font-family: "Source Sans Pro";
  font-size: medium;
  font-weight: 600;
}

.content-right {
  overflow: hidden;
  min-height: 200px;
  padding-top: 15px;
  padding-left: 3%;
}

.content-left {
  float: left;
  margin-right: 25px;
  min-height: 200px;
}

.content-left img {
  max-width: 100%;
}

.pad-20 {
  padding: 20px;
}

.pad-left-20 {
  padding-left: 20px;
}

.content-right.body01 .pad-left-20 {
  padding-bottom: 2px;
}

.btn-sr-input {
  width: 220px;
}

.width-30 {
  width: 30%;
}

#listOfPeerGroup {
  padding-top: 2%;
}

#listOfCutomPeerGroup {
  padding-top: 1%;
}

.submit-peer-group-sec {
  padding-top: 4%;
}

#part3 {
  min-height: 309.875px;
}

.Main-heading {
  margin-bottom: 20px;
}

.Main-heading-mb-0 {
  margin-bottom: 0px !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.flexClass {
  display: flex;
  width: 100%;
}

.content-right .width-50 {
  width: 65%;
}

.font-style {
  font-weight: 400 !important;
}

.print-head {
  display: none;
}

@media print {
  .cpg-info-para, .model-header-sec {
    display: none;
  }
  .print-head {
    display: block;
    text-align: center;
  }
}
.modal.sr-custom-peergroup-modal .modal-dialog {
  max-width: 95% !important;
}

.modal.sr-custom-peergroup-modal .modal-content {
  min-height: 85vh;
  max-height: 90vh;
}

#sr-custom-peergroup-modal {
  margin-bottom: 0px;
}

.model-header-sec {
  clear: both;
  border: none !important;
  padding: 1.5rem 2rem 0 2rem !important;
}

.model-header-title-sec {
  float: left;
  width: 400px;
}

.sr-cpr-action-btns {
  float: right;
  position: absolute;
  top: 17px;
  right: 32px;
}

.sr-cpr-action-btns .btn {
  margin: 0px 2px;
}

/*.sr-custom-peergroup-modal input[type="checkbox"]:disabled {
  background-color: gray !important;
  border-color: gray !important;;
  color: linen;
  opacity: 0.6;
}
.sr-custom-peergroup-modal input[type="checkbox"] {
  position: relative;
  width: 17px;
  height: 16px;
  color: gray;
  border: 1px solid gray;
  appearance: none;
  outline: 0;
  cursor: pointer;
  //transition: background 175ms cubic-bezier(0.1, 0.1, 0.25, 1);
  &::before {
    position: absolute;
    content: '';
    display: block;
    top: -1px;
    left: 5px;
    width: 6px;
    height: 13px;
    border-style: solid;
    border-color: #fff;
    border-width: 0 1px 1px 0;
    transform: rotate(45deg);
    opacity: 0;
  }
  &:checked {
    color: #fff;
    border-color: #1d7366;
    background: #1d7366;
    &::before {
      opacity: 1;
    }
    ~ label::before {
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    }
  }
}
*/
.myreport_title {
  font-weight: bold;
}

.pad-btm-0 {
  padding-bottom: 0;
}

.sr-total-firms {
  line-height: 32px;
}

.sr-add-firms .DataGrid {
  /*max-height: 600px;*/
}

.sr-add-firms-grid-main .DataGrid {
  margin-bottom: 40px;
}

.sr-add-firms-footer {
  position: fixed;
  bottom: -7px;
  background: white;
  width: 100%;
  border-radius: 0;
  padding-top: 10px;
  z-index: 9999;
}

.survey-year-label {
  display: inline-block;
  font-size: 18px;
  position: relative;
  top: -2px;
}

.sr-selected-btn-sec {
  text-align: right;
}

.sr-selected-btn-sec span {
  display: inline-block;
}

/*.sr-peergroup-firms .note-info{
  padding:5px 10px;
}*/
.d-block.modal.sr-custom-peergroup-modal.show {
  height: clamp(40vh, 100vh, max(100vh + 20px));
}

.modal.sr-custom-peergroup-modal .modal-content {
  width: auto;
  height: clamp(30vh, 50vh + 10px, 90vh);
  overflow-y: auto;
  overscroll-behavior-y: auto;
}

.custom-peer-groups-list .DataGridFlexScroll {
  max-height: 45vh;
}

bento-checkbox:not(.disabled):hover + label, bento-checkbox:not(.disabled) + label:hover {
  text-decoration: none;
}

.admin-configurations .DataGrid .wj-colheaders .wj-header {
  letter-spacing: 0 !important;
  word-spacing: 0 !important;
}

.Actions-item.a11y {
  cursor: not-allowed !important;
}
.Actions-item.a11y button[aria-disabled=true] {
  background-color: #f0f0f0 !important;
  border-color: #666 !important;
  box-shadow: none !important;
  color: #404040 !important;
  pointer-events: all !important;
  cursor: not-allowed !important;
}
.Actions-item.a11y button[aria-disabled=true]:hover {
  text-decoration: none !important;
}
.Actions-item.a11y button[aria-disabled=true] .btn-text:hover {
  text-decoration: none !important;
}
.Actions-item.a11y:hover {
  text-decoration: none !important;
}
.Actions-item.a11y .btn:hover span {
  text-decoration: none !important;
}

.bento-tabset .nav-tabs > .nav-item > .nav-link:focus:before {
  border-width: 2px !important;
}

.bento-tabset .nav-tabs > .nav-item > a:focus, .bento-tabset .nav-tabs > .nav-item > a:focus-visible {
  outline-width: 0px !important;
}

.staffing-ratio-add-peer-groups .note-info {
  display: flex;
}
@media (max-width: 820px) {
  .staffing-ratio-add-peer-groups .note-info {
    display: block;
  }
}
.staffing-ratio-add-peer-groups .note-1 {
  display: block;
  flex-grow: 1;
}
.staffing-ratio-add-peer-groups .grid-note {
  font-size: 14px;
}
.staffing-ratio-add-peer-groups .grid-note .shortcut {
  font-weight: bold;
}

/*Zoom CSS*/
a {
  word-break: break-word;
}

.breadcrumb-item, .breadcrumb-item > a, .breadcrumb-item.active {
  font-size: 0.875rem !important;
}

/* Common Zoom Css*/
@media (max-width: 890px) {
  .custom-peer-groups-list .DataGrid div[wj-part=ch], .custom-peer-groups-list .DataGrid .wj-cell {
    height: 38px !important;
  }
  .custom-peer-groups-list .DataGrid .wj-cells {
    top: 38px !important;
  }
}
@media (max-width: 800px) {
  .chart-visualization .displayCss, .chart-list-type .Card-group {
    display: block !important;
  }
  .chart-visualization .displayCss .bento-desc-list-item.w-30, .chart-visualization .displayCss .bento-desc-list-item.w-70,
  .chart-list-type .Card-item--large, .chart-list-type .Card-item--small {
    width: 100% !important;
  }
  .chart-list-type .Card-item--small {
    padding-left: 0 !important;
  }
  .sr-cpr-action-btns {
    float: none !important;
    position: relative !important;
    top: 0 !important;
    right: 0 !important;
  }
  .sr-custom-peergroup-modal .modal-header {
    display: block !important;
    padding: 15px !important;
  }
}
@media (max-width: 690px) {
  /*.chart-visualization .displayCss{
    display: block !important;
  }
  .chart-visualization .displayCss .bento-desc-list-item.w-30, .chart-visualization .displayCss .bento-desc-list-item.w-70{
    width:100% !important;
  }*/
  .FilterPanel--toggle.is-open .FilterPanel-data {
    display: none !important;
  }
  .FilterPanel--toggle .FilterPanel-data {
    display: block !important;
  }
  .FilterPanel--toggle.is-open .FilterPanel-control {
    max-width: 41.875rem !important;
  }
  .FilterPanel--toggle .FilterPanel-control {
    max-width: 24rem !important;
  }
  bento-toolbar.global-subnav .navbar-right {
    padding-right: 0.425rem !important;
  }
  .FilterPanel--toggle.is-open {
    transform: translateX(0);
    width: 97% !important;
  }
  .content-left {
    float: none !important;
  }
  .content-right .width-30 {
    width: 40% !important;
  }
  .content-right .width-50 {
    width: 60% !important;
  }
}
@media screen and (max-width: 690px) and (min-width: 601px) {
  .global-header .topbar-right {
    margin-right: 10px !important;
  }
  .Header .Header-item--medium {
    margin-right: 0.725rem !important;
  }
  .username-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 85px;
  }
}
@media screen and (max-width: 500px) and (min-width: 330px) {
  .global-header-text .topbar-left {
    margin-left: 8px !important;
  }
  bento-toolbar.global-subnav .navbar-right {
    padding-right: 0.2rem !important;
  }
  .Header .topbar-left {
    height: 45px !important;
    margin-left: 0px !important;
    padding-left: 12px !important;
  }
  .Header .topbar-right .Header-item a {
    padding: 0px 5px !important;
  }
  .HeaderSelectFirm {
    max-width: 4rem !important;
  }
  .Header .Header-item--small {
    margin-right: 0.35rem !important;
  }
  .FilterPanel--visualization {
    padding: 0.5rem !important;
  }
  .chart-container {
    overflow-y: unset !important;
  }
}
@media (max-width: 350px) {
  /*.Body{
    word-break: break-word !important;
  }*/
  /*.FilterPanel--toggle .FilterPanel-control {
    max-width: rem-calc(320);
  }
  .FilterPanel--toggle .FilterPanel-data {
    width: calc(100% - 20rem);
  }*/
  .Header .topbar-left {
    height: 45px !important;
    margin-left: 0px !important;
    padding-left: 12px !important;
  }
  .Header .topbar-right .Header-item a {
    padding: 0px 5px !important;
  }
  .HeaderSelectFirm {
    max-width: 4rem !important;
  }
  .Header .Header-item--small {
    margin-right: 0.35rem !important;
  }
  .custom-peer-groups-list .DataGrid {
    max-height: 62vh;
  }
  /*.usermenu-dropdown-item{
    display: block !important;
  }*/
  .global-header .topbar-item {
    height: 42px !important;
    line-height: 42px !important;
  }
  /*bento-toolbar.global-subnav .navbar-right{
    float: left !important;
  }*/
  .Header .topbar-right {
    padding-left: 1rem !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .Header .Header-item--medium {
    margin-right: 0.25rem !important;
  }
  .Header .Header-logo {
    margin-right: 0px !important;
    margin-left: 0px !important;
    padding-left: 0px !important;
  }
  .global-header .topbar-item .dropdown-toggle {
    padding-left: 4px !important;
  }
  .chart-visualization .Actions-item {
    margin-left: 0.07rem !important;
  }
  .Section--overflow .Section-body {
    margin-right: -0.25rem !important;
  }
  .FilterPanel-data .row {
    margin-left: -15px !important;
  }
  .container, .container-fluid, .container-xl, .container-lg, .container-md, .container-sm {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .FilterPanel--visualization {
    padding: 1rem !important;
  }
  .bento-toolbar.global-subnav .navbar-right {
    padding-right: 0px !important;
  }
  /*.wj-cell.wj-header{width:200px !important;}
  .wj-cells.wj-frozen-clone{left:200px !important;}
  .wj-cells{width:200px !important;}*/
  .username-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 85px;
  }
  bento-toolbar.global-subnav .navbar-right {
    padding-right: 1rem !important;
  }
  .FilterPanel--toggle.is-open .FilterPanel-control {
    max-width: 18.75rem !important;
    min-width: 18.75rem !important;
  }
  .Actions .bento-search .form-control {
    min-width: 11.75rem !important;
  }
  .content-right .flexClass {
    display: block !important;
  }
  .content-right .width-30 {
    width: 100% !important;
  }
  .content-right .width-50 {
    width: 100% !important;
  }
  app-staffing-ratio .card .card-body {
    padding: 0.5em !important;
  }
  #first ul {
    padding-left: 10px !important;
  }
  #first .pad-20, #first .pad-left-20 {
    padding-left: 0px !important;
  }
  /*wj-transposed-grid  .wj-row .wj-header{
    width:140px !important;
    padding: 0.325rem !important;
    white-space: normal;
  }
  wj-transposed-grid  .wj-cells, [wj-part="cf"], [wj-part="ch"], [wj-part="bl"]{left:140px !important;}
  wj-transposed-grid  .wj-cell:not(.wj-hasdropdown){
        text-overflow: unset !important;
  }
  wj-transposed-grid [wj-part="rh"] {
    width : 140px !important;
  }*/
}
@media (max-width: 290px) {
  .FilterPanel--toggle.is-open .FilterPanel-control {
    max-width: 15.3125rem !important;
    min-width: 15.3125rem !important;
  }
  .FilterPanel--visualization {
    padding: 0.7rem !important;
  }
  .custom-peer-groups-list .DataGrid {
    max-height: 72vh;
  }
  .sr-custom-peergroup-modal .wj-control.wj-columnfiltereditor button[wj-part=btn-apply] {
    margin-right: 3rem;
  }
  .chart-container {
    overflow-x: hidden;
  }
  .FilterPanel--toggle .FilterPanel-data {
    width: calc(100% - 16rem) !important;
  }
  .usermenu-dropdown-item {
    display: block !important;
  }
  .usermenu-dropdown-item .username-text {
    white-space: unset;
    overflow: unset;
    text-overflow: unset;
    width: auto;
  }
  .card .card-body {
    padding: 1.5em !important;
  }
  .FilterPanel--toggle .FilterPanel-header {
    padding: 0.45rem !important;
  }
  .wj-flexchart .wj-axis-x .wj-title, .wj-flexchart .wj-axis-y .wj-title, .wj-flexchart .wj-label {
    font-size: 0.75rem !important;
  }
  .bui-bento-side-overlay-container-wrapper .bento-side-overlay-container, app-bento-multiselect-list {
    min-width: 245px !important;
  }
  .visualization-templates-list .Actions-flex {
    position: relative;
    left: 10px;
  }
  .Actions .bento-search .form-control {
    min-width: 8rem !important;
  }
  .chart-visualization .Actions {
    display: block !important;
  }
  .chart-visualization .ViewPanel-header .Actions .Actions-item:first-child {
    float: left;
    margin-right: 0.07rem;
  }
  .chart-visualization .ViewPanel-header .Actions .Actions-item {
    margin-bottom: 0.07rem !important;
  }
  .btn-sr-input {
    width: 200px !important;
    padding: 0.3125rem 0.3375rem !important;
  }
}
@media (max-width: 220px) {
  .Main-header {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }
  .GlobalLayout-item {
    padding: 0.25rem !important;
  }
  .Header .topbar-left {
    padding-left: 5px !important;
  }
  .Header .topbar-left svg {
    width: 190px;
  }
  .Header .topbar-right {
    padding-left: 0.625rem !important;
  }
  .visualization-templates-list .Actions-flex {
    /*flex-flow: unset !important;
    margin-top: 2rem;
    column-gap: 1px;*/
    position: relative;
    left: 20px;
  }
  .FilterPanel--toggle.is-open .FilterPanel-control {
    max-width: 11.875rem !important;
    min-width: 11.875rem !important;
  }
  .FilterPanel--toggle .FilterPanel-data {
    width: calc(100% - 15rem) !important;
  }
  .bui-bento-side-overlay-container-wrapper .bento-side-overlay-container, app-bento-multiselect-list {
    min-width: 195px !important;
  }
  /*.chart-container {
    height: 100%;
    width:200px;
    overflow-x: auto;
    overflow-y: auto;
  }*/
  .bento-search {
    width: 60% !important;
  }
  .wj-legend text {
    inline-size: 170px;
  }
  .FilterPanel--visualization {
    padding: 0.3rem !important;
  }
  .FilterPanel--toggle .FilterPanel-header {
    padding: 0.2rem !important;
  }
  .FilterPanel-header button {
    position: relative;
    left: 3px;
  }
  .wj-flexchart .wj-axis-x .wj-title, .wj-flexchart .wj-axis-y .wj-title, .wj-flexchart .wj-label {
    font-size: 0.68rem !important;
  }
  .btn-sr-input {
    width: 170px !important;
  }
  .chart-visualization .ViewPanel-header .col {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}