.OrderedList {
  margin-bottom: 0;
  padding-left: rem-calc(28);
}
.OrderedList li {
  margin-bottom: rem-calc(8);
  margin-top: rem-calc(8);
}
.PeerMeta {
  margin-bottom: 0;
}
.PhoneMeta {
  max-width: rem-calc(220);
}
.UserMeta {
  margin-bottom: 0;
  margin-top: rem-calc(20);
  max-width: rem-calc(300);
}
.PeerMeta dt {
  @include media('>=medium') {
    margin-top: 0;
  }
}
.FirmDetails.bento-desc-list dt,
.FirmDetails.bento-desc-list dd {
  font-size: rem-calc(14);
}
.PeerMeta.bento-desc-list dt {
  line-height: rem-calc(24);
}
.PhoneMeta.bento-desc-list dd {
  @include media('>=medium') {
    margin-bottom: 0;
  }
}
.PhoneMeta.bento-desc-list dt,
.PhoneMeta.bento-desc-list dd {
  @include media('>=medium') {
    margin-top: 0;
  }
}
.PeerMeta.bento-desc-list--inline dt,
.PeerMeta.bento-desc-list--inline dd {
  display: inline;
}
.PeerMeta--small.bento-desc-list dt,
.PeerMeta--small.bento-desc-list dd {
  font-size: rem-calc(14);
}
.PeerMeta.bento-desc-list--inline .dtCss{
  font-weight: 600;
}
.PeerMeta.bento-desc-list--inline .ddCss{
  margin:0rem 0rem 0rem 0.1875rem;
  @include media('>=medium') {
    margin: 0rem 0rem 0rem 0.25rem;
  }
}
.PeerMeta.bento-desc-list--inline .bento-desc-list-item {
  float: none;
  padding: 0;
}
// Unable to group: Stylelint requires that I move this before the rule above
.PhoneMeta.bento-desc-list dt + dd:not(:first-of-type) {
  @include media('>=medium') {
    margin-top: 0;
  }
}
.chart-list-type {
  list-style-type: none;
}
