.Main .bento-tabset .nav-tabs {
  border-color: bento-color(gray, 400);
  padding-left: rem-calc(20);
  padding-right: rem-calc(20);
}
.Main .bento-tabset .nav-tabs > .nav-item > .nav-link {
  padding-left: 0;
  padding-right: 0;
}
.Main .bento-tabset .tab-content {
  padding-top: 0;
}
.Main .bento-tabset .tab-content,
.Main .bento-tabset .tab-pane {
  @extend .u-flexGrowCol;
}

// Component specific
.Section--stats .bento-tabset .nav-tabs {
  margin-bottom: rem-calc(20);
}
