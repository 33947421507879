.Actions {
  @include media('>=medium') {
    align-items: center;
    display: flex;
  }
}
.Actions-flex {
  display: flex;

  @include media('<=medium') {
    align-content: flex-end;
    flex-flow: column wrap;
  }
}
.Actions-item-flex-end {
  margin-top: 0;
}

.chart-visualization .Actions{
  display: flex;
  align-items: center;
}
.chart-visualization .Actions-item {
  margin-top: 0 !important;
  margin-left: rem-calc(20) !important;
}
// Descendents
.Actions-item {
  margin-top: rem-calc(20);

  &:first-child {
    margin-top: 0;
  }

  @include media('>=medium') {
    margin-left: rem-calc(20);
    margin-top: 0;

    &:first-child {
      margin-left: 0;
    }
  }
}
.Actions-flex > .Actions-item-flex {
  align-items: center;
  padding-top: 0;
}
.Actions-item--indicator {
  @include bento-typeset(paragraph);

  max-height: rem-calc(24);
  padding-top: rem-calc(2); // Adjust vertical alignment
}
.Actions-item--indicator .bento-icon-star {
  color: bento-color(indigo, 700);
}
.Actions-button,
.Actions-button > .bento-busyloader-blocker {
  display: inline-flex;
}
.Actions-button > .bento-busyloader-blocker {
  margin: rem-calc(-2) rem-calc(-2) rem-calc(-2) rem-calc(12) !important; // Adjust position
  position: static;
  transform: translate(0, 0);
}
.Actions-dropDownButton {
  min-width: rem-calc(140);
}
.Actions-dropDownButton .btn-text {
  flex-grow: 1;
}
.Actions-sortButton {
  align-items: center;
  display: flex;
}
.Actions-item .btn .bento-icon-filter-sorting-down {
  margin-top: rem-calc(2); // Adjust veritcal alignment
}
.Actions-item .btn.btn-icon .bento-icon-caret-up-filled,
.Actions-item .btn.btn-icon .bento-icon-caret-down-filled {
  margin-left: rem-calc(16);
}

// Sizes
.Actions-item--small {
  @include media('>=medium') {
    margin-left: rem-calc(8);

    &:first-child {
      margin-left: 0;
    }
  }
}
.Actions-item--large {
  @include media('>=medium') {
    margin-left: rem-calc(28);

    &:first-child {
      margin-left: 0;
    }
  }
}

// Component specific
.Section-footer .Actions {
  margin-top: rem-calc(20);
}
.Section--overlap .Actions {
  left: 0;
  top: 0;
  z-index: 5;
}
.card .Actions-item {
  @include media('>=medium') {
    margin-left: rem-calc(8);
  }
}
.Actions-view-data {
  display: inline-flex;
  justify-content: space-between;
  width: 60px;
}
.Actions-item-view-data {
  margin-left: 0;
  margin-top: 0 !important;
}
.tooltip-shown {
  width: max-content;
}
.timeKeepers{
  .tooltip-shown {
    @include media('<=small') {
        width: 140px;
        left: -156px !important ; 
    }    
  }
}
.tooltip{
  z-index: 10 !important;
}

.transfer bento-checkbox {
  overflow: unset !important;
}
.cursor-pointer {
  cursor: pointer;
}
.max-width-100 {
  max-width: 100px !important;
}
.freez-unfreez-actions-main{
  position: relative;
  display: inline;
}
.Visualization-label-col {
  // TODO: CSS modification for list label
  // @include media('<=small') {
  //   min-width: rem-calc(240);
  // }
  // @include media('<=medium') {
  //   min-width: rem-calc(320);
  // }
  // @include media('>=large') {
  //   min-width: rem-calc(400);
  // }
}

.dropdown-menu[x-placement^=top], .dropdown-menu[x-placement^=right], .dropdown-menu[x-placement^=bottom], .dropdown-menu[x-placement^=left]{
  @include media('<=small') {
      left: 34px !important;
      width: 192px;
    }
}
.popover{
  @include media('<=small') {
    font-size: 7px !important;
  }
  
}
.popover-header{
  @include media('<=small') {
    font-size: 8px !important;
  }
}