// ----------------------------------------------------
// SVG
// ----------------------------------------------------
.SVG {
  height: auto;
  width: 100%;
}

@font-face {
  font-family: 'Clario';
  src: url(fonts/Clario-Regular.otf);
  font-weight: normal;
}
@font-face {
  font-family: 'Clario-Bold';
  src: url(fonts/Clario-Bold.otf);
  font-weight: bold;
}

// Modifiers
.SVG--logo {
  // max-width: rem-calc(220);
  min-width: rem-calc(220);
  @include media('<=medium') {
   min-width: none;
  }
  img{
    @include media('<=medium') {
     max-width: 100%;
    }
    
  }
  .FIlogo{
  vertical-align: -webkit-baseline-middle !important;
  @media screen and (max-width: 470px) and (min-width: 200px){ 
    display: none !important;
   }

  }
  // .TRLogo-text{ 
  //   color: #fff;
  //   font-size: 20px;
  //   font-family: 'Clario',Arial, Helvetica, sans-serif;
  //   position: relative;
  //   top: 5px;
  //   display: inline-block
  // }
}
.SVG--building {
  max-width: rem-calc(112);
  min-width: rem-calc(112);
}
