.ZeroState {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  text-align: center;
}
.ZeroState-header {
  margin-bottom: rem-calc(8);
  max-height: rem-calc(96);
  width: auto;
}
.ZeroState-header svg {
  height: 100%;
  width: 100%;
}
.ZeroState-body {
  font-weight: bento-weight(normal);
  max-width: rem-calc(400);
}
.ZeroState-actions {
  border-top: 1px solid bento-color(gray, 300);
  padding-top: rem-calc(16);
}
.ZeroState-list {
  list-style: none;
  margin: 0;
  padding: 0;
}
.ZeroState-item {
  margin-bottom: rem-calc(16);

  &:last-child {
    margin-bottom: 0;
  }
}
