.Card {
  margin-top: rem-calc(20);

  &:first-child {
    margin-top: 0;
  }
}
.Card-header {
  background-color: bento-color(blueGray, 100);
  border: 1px solid bento-color(gray, 400);
  padding: rem-calc(12) rem-calc(16);
}
.Card-heading {
  margin-bottom: 0;
}
.Card-body {
  background-color: bento-color(white);
  border: 1px solid bento-color(gray, 400);
  border-top: none;
  margin-top: -1px;
  padding: 0 rem-calc(12) rem-calc(16);
}
.Card-body.collapse {
  display: none;
}
.Card-body.collapse.show {
  background-color: bento-color(blueGray, 100);
  display: block;
}
.Card-body.collapse.show.bento-is-busy {
  min-height: rem-calc(80);
}
.Card-details {
  border-top: 1px solid bento-color(gray, 400);
  margin-left: rem-calc(16);
  margin-right: rem-calc(16);
  padding-bottom: rem-calc(16);
  padding-top: rem-calc(16);
}
.Card-group {
  display: flex;
}
.Card-item--large {
  width: 70%;
}
.Card-item--small {
  padding-left: rem-calc(20);
  width: 30%;
}
.Card .card-body {
  padding: rem-calc(16);
}

// Component specific
.Section--views .Card {
  margin-bottom: rem-calc(12);
}
.Section--views .Card-title {
  @include bento-typeset(paragraph-large);

  font-weight: bento-weight(semiBold);
  margin-bottom: 0;
}
.FilterPanel--createView .Card-heading {
  font-size: rem-calc(16);
}
