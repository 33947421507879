// ----------------------------------------------------
// Global Sidebar
// ----------------------------------------------------
// Accordion
// ----------------------------------------------------
// Header
app-global-sidebar .bento-accordion.accordion > .card .card-header,
app-global-sidebar .bento-accordion.accordion > .card:last-child .card-header button[aria-expanded='false'] {
  border-radius: 0;
}
app-global-sidebar .bento-accordion.accordion .card-header button {
  border-color: color(gray, 500);
  border-left-width: 0;
  border-right-width: 0;
}
app-global-sidebar .bento-accordion.accordion > .card:first-child .card-header button {
  border-bottom-color: color(gray, 500);
  border-left-width: 0;
  border-radius: 0;
  border-right-width: 0;
  border-top-width: 0;
}
app-global-sidebar .bento-accordion.accordion .card-header button[aria-expanded='true'] {
  border-color: color(gray, 500);
}

// Body
app-global-sidebar .bento-accordion.accordion .card .collapse.show {
  border-color: color(gray, 500);
  border-width: 0;
}
app-global-sidebar .bento-accordion.accordion > .card:last-child .collapse.show {
  border-bottom-width: 1px;
  border-radius: 0;
}
