// ----------------------------------------------------
// Side navigation
// ----------------------------------------------------
bento-side-nav.NavSide {
  border-right: none;
  padding-bottom: 0;
  padding-top: 0;
}
.NavSide ul {
  margin-bottom: 0;
}
bento-side-nav.NavSide > ul {
  border-top: none;
}
.NavSide > ul > li {
  background-color: bento-color(white);
}
.NavSide > ul > li > ul {
  padding-top: 0;
}
.NavSide > ul > li > .label {
  padding-left: rem-calc(20) !important;
}
.NavSide > ul > li > ul > li {
  border-top: 1px solid bento-color(gray, 400);
  margin-bottom: 0;
}
.NavSide > ul > li > ul > li > .label {
  padding-left: 0 !important;
}
.NavSide > ul > li > ul > li > .label a {
  text-decoration: none;
}
.NavSide > ul > li > ul > li > .label a:hover {
  text-decoration: underline;
}
.NavSide > ul > li > ul > li > .label a > span {
  display: block;
  padding: rem-calc(18) 0 rem-calc(18) rem-calc(60);
}
.NavSide.bui-side-nav-collapsible > ul > li > .label {
  padding-left: rem-calc(36) !important;
}
.NavSide [bentoSideNavItem] > .label a[aria-current='page'] > span::before {
  background-color: bento-color(indigo, 200);
}
.NavSide [bentoSideNavItem] > .label a > span::after,
.NavSide [bentoSideNavItem] > .label a:hover > span::after,
.NavSide [bentoSideNavItem] > .label a[aria-current='location'] > span::before {
  background-color: transparent; 
}
.NavSide [bentoSideNavItem] > .label a[aria-current='location']:hover > span::before {
  background-color: bento-color(blueGray, 200);
}
.NavSide [bentoSideNavItem] > .label a.disabled {
  color: bento-color(gray, 400);
}
bento-side-nav.NavSide > ul > li[bentoSideNavItem] > div.label a {
  padding-right: rem-calc(20);
}
.NavSide li[bentoSideNavItem] div.label a > span {
  display: flex;
}
.NavSide .badge {
  margin-left: auto;
}
.NavSide.bui-side-nav-collapsible li[bentoSideNavItem] div.label a > span::after {
  left: -32px;
}

// ----------------------------------------------------
// Breadcrumb
// ----------------------------------------------------
.NavBreadcrumb {
  margin-bottom: rem-calc(12);
}
// Todo: Brian will work on it, CSS for combobox inside split office Modal
.bui-bento-combobox-container.bento-combobox-container.bento-append-to.top.in,
.bui-bento-combobox-container.bento-combobox-container.bento-append-to.bottom.in {
  z-index: 3000;
}
bento-toolbar.global-subnav [ngbdropdown] .dropdown-menu.dropdown-menu-right {
  left: -5px;
  right: 0;
}
bento-toolbar.global-subnav .navbar-nav > li > [ngbdropdown] > button {
  line-height: normal;
}
/*Focus border css Start here*/
.NavSide [bentoSideNavItem] > .label a[aria-current=page] > span::before {
  background-color: transparent !important; 
}
.NavSide.bui-side-nav-collapsible > ul > li > .label, .NavSide > ul > li > .label {
    padding-left: 0px !important;
    margin-left: 5px !important;
}
bento-side-nav > ul > li[bentoSideNavItem] > div.label a {
    padding: 18px;
}
.NavSide.bui-side-nav-collapsible > ul > li > div.label a {
  padding-left: 2.25rem !important;
}

bento-side-nav > ul > li[bentoSideNavItem] > div.label a:focus, bento-side-nav > ul > li[bentoSideNavItem] > div.label a:focus-visible{
  /*border:solid 2px red;*/
  outline-width: 2px !important;
}
.NavSide [bentoSideNavItem] > .label a[aria-current=page] {
  background-color: rgb(227, 232, 250);
}
.bento-side-nav-branch[bentoSideNavItem] button.btn-expand-collapse:focus span {
  outline: 2px dashed;
}
a:focus, a:focus-visible, bento-toolbar.global-subnav .navbar-nav > li > button:focus,
 bento-toolbar.global-subnav .navbar-nav > li > a:focus, bento-toolbar.global-subnav .navbar-right > li > a:focus, bento-toolbar.global-subnav .navbar-nav > li > [ngbdropdown] > button:focus,
  bento-toolbar.global-subnav .navbar-nav > li > [ngbdropdown] > a:focus, .cdk-focused, .cdk-keyboard-focused
{
  outline-width: 2px !important;
}
.btn i.bento-combobox-dropdown-button-icon {
  position: absolute;
  top:1px !important;
  bottom:1px;
  left:2px;
  right:2px;
  display: flex;
  justify-content: center;
  &:focus {
    &.cdk-keyboard-focused,
    &.cdk-program-focused {
      outline: 2px dashed;
    }
  }
}
bento-toolbar.global-subnav [ngbdropdown] .dropdown-menu button.cdk-program-focused:before, bento-toolbar.global-subnav [ngbdropdown] .dropdown-menu button.cdk-keyboard-focused:before, bento-toolbar.global-subnav [ngbdropdown] .dropdown-menu a.cdk-program-focused:before, bento-toolbar.global-subnav [ngbdropdown] .dropdown-menu a.cdk-keyboard-focused:before{
border-width: 2px !important;
}

.global-header a:focus, .global-header a:focus-visible, #menu-sorting .dropdown-item.cdk-focused, #menu-sorting .dropdown-item.cdk-keyboard-focused{
  outline-width: 0 !important;
}
ngb-toast.bento-toast-default .cdk-keyboard-focused.close, ngb-toast button.cdk-keyboard-focused.bento-toast-action-btn.btn-outline-primary, .cdk-keyboard-focused.btn-secondary.btn-ghost,
.cdk-keyboard-focused.btn-outline-secondary.btn-icon.btn-ghost,
.cdk-keyboard-focused.btn-flat-secondary.btn-ghost,
.cdk-keyboard-focused.btn-flat-icon.btn-ghost, ngb-toast.bento-toast-default .cdk-program-focused.close, ngb-toast button.cdk-program-focused.bento-toast-action-btn.btn-outline-primary, .cdk-program-focused.btn-secondary.btn-ghost,
.cdk-program-focused.btn-outline-secondary.btn-icon.btn-ghost,
.cdk-program-focused.btn-flat-secondary.btn-ghost,
.cdk-program-focused.btn-flat-icon.btn-ghost {
  border-width: 2px !important;
  //border: 1px dashed currentColor;
}

bento-splitter-group .cdk-keyboard-focused.btn.btn-outline-primary.bento-splitter-toggle,
bento-splitter-group .cdk-keyboard-focused.btn.btn-outline-primary.bento-splitter-decrease,
bento-splitter-group .cdk-keyboard-focused.btn.btn-outline-primary.bento-splitter-increase, .cdk-keyboard-focused.btn.btn-outline-secondary:not(.btn-ghost),
.cdk-keyboard-focused.btn-icon.btn-outline-secondary:not(.btn-ghost),
.cdk-keyboard-focused.btn.btn-flat-secondary,
.cdk-keyboard-focused.btn.btn-flat-icon, .bento-toolbar .navbar-nav > li > [ngbdropdown] > .cdk-keyboard-focused[ngbDropdownToggle], .bento-toolbar .navbar-nav .cdk-keyboard-focused.btn-toolbar, ngb-toast .cdk-keyboard-focused.close, bento-scrollable-toolbar .bento-toolbar .bento-scrollable-toolbar-inner bento-scrollable-toolbar-item > button.cdk-keyboard-focused,
bento-scrollable-toolbar .bento-toolbar .bento-scrollable-toolbar-inner bento-scrollable-toolbar-item > [ngbdropdown] > .cdk-keyboard-focused[ngbDropdownToggle], .bento-off-canvas-menu > .bento-off-canvas-menu-aside-wrapper .bento-off-canvas-menu-aside button.cdk-keyboard-focused.bento-off-canvas-menu-close-button,
.bento-off-canvas-menu > .bento-off-canvas-menu-aside-right-wrapper .bento-off-canvas-menu-aside-right button.cdk-keyboard-focused.bento-off-canvas-menu-close-button, .modal-content > .cdk-keyboard-focused.close,
.modal-content > bento-modal-confirmation > .cdk-keyboard-focused.close, .bento-transferbox-wj-dropdown-panel.wj-control.wj-columnfiltereditor button.cdk-keyboard-focused[wj-part=btn-asc], .bento-transferbox-wj-dropdown-panel.wj-control.wj-columnfiltereditor button.cdk-keyboard-focused[wj-part=btn-dsc], bento-transferlist .bento-toolbar .navbar-list > li .cdk-keyboard-focused.btn.transferbox-transfer-button, .bento-toolbar .cdk-keyboard-focused.btn-icon, .bento-splitter-group > .bento-splitter-group-panel > .bento-splitter-handle .cdk-keyboard-focused.btn.btn-primary, .bui-bento-side-overlay-container-wrapper .cdk-keyboard-focused.btn-multiselect-overlay, bento-number-input .cdk-keyboard-focused.bento-number-input-button, bento-combobox .cdk-keyboard-focused.btn-icon, bento-combobox .bui-bento-combobox-footer-item .cdk-keyboard-focused.btn, .cdk-keyboard-focused.btn.btn-outline-primary,
.cdk-keyboard-focused.btn.btn-link, bento-splitter-group .cdk-program-focused.btn.btn-outline-primary.bento-splitter-toggle,
bento-splitter-group .cdk-program-focused.btn.btn-outline-primary.bento-splitter-decrease,
bento-splitter-group .cdk-program-focused.btn.btn-outline-primary.bento-splitter-increase, .cdk-program-focused.btn.btn-outline-secondary:not(.btn-ghost),
.cdk-program-focused.btn-icon.btn-outline-secondary:not(.btn-ghost),
.cdk-program-focused.btn.btn-flat-secondary,
.cdk-program-focused.btn.btn-flat-icon, .bento-toolbar .navbar-nav > li > [ngbdropdown] > .cdk-program-focused[ngbDropdownToggle], .bento-toolbar .navbar-nav .cdk-program-focused.btn-toolbar, ngb-toast .cdk-program-focused.close, bento-scrollable-toolbar .bento-toolbar .bento-scrollable-toolbar-inner bento-scrollable-toolbar-item > button.cdk-program-focused,
bento-scrollable-toolbar .bento-toolbar .bento-scrollable-toolbar-inner bento-scrollable-toolbar-item > [ngbdropdown] > .cdk-program-focused[ngbDropdownToggle], .bento-off-canvas-menu > .bento-off-canvas-menu-aside-wrapper .bento-off-canvas-menu-aside button.cdk-program-focused.bento-off-canvas-menu-close-button,
.bento-off-canvas-menu > .bento-off-canvas-menu-aside-right-wrapper .bento-off-canvas-menu-aside-right button.cdk-program-focused.bento-off-canvas-menu-close-button, .modal-content > .cdk-program-focused.close,
.modal-content > bento-modal-confirmation > .cdk-program-focused.close, .bento-transferbox-wj-dropdown-panel.wj-control.wj-columnfiltereditor button.cdk-program-focused[wj-part=btn-asc], .bento-transferbox-wj-dropdown-panel.wj-control.wj-columnfiltereditor button.cdk-program-focused[wj-part=btn-dsc], bento-transferlist .bento-toolbar .navbar-list > li .cdk-program-focused.btn.transferbox-transfer-button, .bento-toolbar .cdk-program-focused.btn-icon, .bento-splitter-group > .bento-splitter-group-panel > .bento-splitter-handle .cdk-program-focused.btn.btn-primary, .bui-bento-side-overlay-container-wrapper .cdk-program-focused.btn-multiselect-overlay, bento-number-input .cdk-program-focused.bento-number-input-button, bento-combobox .cdk-program-focused.btn-icon, bento-combobox .bui-bento-combobox-footer-item .cdk-program-focused.btn, .cdk-program-focused.btn.btn-outline-primary,
.cdk-program-focused.btn.btn-link {
  border-width: 2px !important;
}

.global-header .topbar-left a:focus {
  box-shadow: none !important;
  outline: 2px dashed #c4c0b5 !important;
  outline-offset: -2px !important;
  z-index: 1;
}
.global-header .topbar-right .dropdown .cdk-keyboard-focused.btn.btn-flat-secondary{
  border: 0px dashed #083899 !important;
}
.dropdown-item:focus:not(.btn-primary)::before{
  border-width: 2px !important; 
}
.global-header .topbar-item > a:focus, .global-header .topbar-item > button:focus{
  outline: 2px dashed white;
}
bento-checkbox.cdk-program-focused [aria-checked=true] ~ .bui-checkbox-unchecked, bento-checkbox.cdk-program-focused [aria-checked=mixed] ~ .bui-checkbox-unchecked, bento-checkbox.cdk-keyboard-focused [aria-checked=true] ~ .bui-checkbox-unchecked, bento-checkbox.cdk-keyboard-focused [aria-checked=mixed] ~ .bui-checkbox-unchecked{
  box-shadow: inset 0 0 0 1px #1B1A1A !important;
}
bento-checkbox.cdk-program-focused, bento-checkbox.cdk-keyboard-focused {
  box-shadow: inset 0 0 0 2px #1B1A1A !important;
}
/*bento-checkbox .bui-checkbox-unchecked {
  border-color: #0D0909 !important;
}*/
/*Focus border css Start here*/
