.Chart-Area {
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;
}

// SVG charts
.Chart {
  background-color: bento-color(white);
  border: 1px solid bento-color(gray, 400);
  height: 100%;
  margin: 0;
  padding: rem-calc(20) 0 rem-calc(20) rem-calc(20);
  width: 100%;
}
.Chart ellipse {
  rx: 3; // Adjust radius size of plot
  ry: 3; // Adjust radius size of plot
  stroke-width: 1;
}
.Chart ellipse:hover {
  rx: 4; // Adjust radius size of plot
  ry: 4; // Adjust radius size of plot
  stroke-opacity: 0.3;
  stroke-width: 17;
}
.Chart polyline {
  stroke-width: 1;
}
.wj-flexchart {
  font-family: Arial, sans-serif;
  height: 520px;
  width: 100%;
}
.wj-flexchart .wj-axis-x .wj-title,
.wj-flexchart .wj-axis-y .wj-title,
.wj-flexchart .wj-label {
  font-size: rem-calc(13);
}
.wj-flexchart .wj-legend > g .wj-label
{
  letter-spacing:0 !important ;
  word-spacing:0 !important;
}
.Visualization .wj-gridline {
  opacity: 0.2;
}
.Visualization .wj-gridline-minor {
  opacity: 0.5;
}
.Visualization .wj-plot-area rect {
  stroke: #666;
  stroke-opacity: 0.5;
}
.Chart .wj-axis-x .wj-line,
.Chart .wj-axis-y .wj-gridline {
  stroke: bento-color(gray, 900);
  stroke-width: 1;
  transform: translateX(#{rem-calc(-10)});
}
.Chart .wj-axis-y .wj-gridline {
  opacity: 1;
  stroke-width: 0.5;
}
.Chart .wj-axis-x .wj-gridline,
.Chart .wj-axis-y .wj-gridline-minor {
  opacity: 1;
  stroke: bento-color(gray, 300);
  stroke-width: 1;
}
.Chart .wj-axis-y .wj-gridline-minor {
  transform: translateX(#{rem-calc(-10)});
}
.Chart .wj-axis-x .wj-title,
.Chart .wj-axis-y .wj-title,
.Chart .wj-legend .wj-title {
  font-style: bento-weight(normal);
}
.Chart .wj-axis-x .wj-title,
.Chart .wj-axis-y .wj-title,
.Chart .wj-label {
  font-size: rem-calc(14);
  width: fit-content;
}
.wj-tooltip {
  background-color: bento-color(gray, 900);
  border-radius: 2px;
  color: bento-color(white);
  font-size: rem-calc(14);
  line-height: rem-calc(24);
  padding: 8px;
  text-align: center;
}
.wj-tooltip::after {
  display: none;
}
.wj-data-label {
  background: #fff;
  // border: 2px solid #dcdcdc;
  // border-radius: 5px;
  font-size: 11px;
  font-weight: 600;
  padding: 2px;
  padding-bottom: 0;
}
.one-offs{
  .wj-control:focus,
  .wj-control :focus,
  .wj-control a:focus {
  // outline: black dashed 2px !important;
  //Reverting back as it was on prod - Pending fix.
  outline: 1px dashed #083899 !important;
  outline-width: 2px !important;
  }
} 
.wj-control:focus,
.wj-control :focus,
.wj-control a:focus {
 // outline: black dashed 2px !important;
 //Reverting back as it was on prod - Pending fix.
 outline: black dashed 1px !important;
}

//Removing borders for grids across. 
// .DataGrid--views.wj-control :focus, .DataGrid--views.wj-control:focus{
//   outline: black dashed 0px !important;
//   border: black dashed 2px !important;
// }

#cc-resetBtn-DPA:focus-visible,
#cc-resetBtn-KPM:focus-visible,
#cc-resetBtn-KPMD:focus-visible,
#cc-resetBtn-DPA:focus,
#cc-resetBtn-KPM:focus,
#cc-resetBtn-KPMD:focus {
  outline: 2px dashed #000 !important;
}
.chart-reset-button {
  background: transparent !important;
  border: transparent !important;
}
.chart-list-type {
  list-style-type: none !important;
  padding-inline-start: 0 !important;
}
.chart-req-reset-sec{
  display: block;
  padding-left: 1.25rem;
  padding-bottom: 0.5rem;
}
.chart-req-reset-sec .chart-reset-button{
  float:right;
  position: relative;
  top: -5px;
}

.modal-content .modal-footer.modal-common-action-btns-footer{
  flex-direction:unset;
  justify-content:flex-end !important;
}
.modal-content .modal-footer.modal-common-action-btns-footer .btn.btn-outline-secondary{
  margin-right:0.25rem !important;
}
.modal-content .modal-footer.modal-common-action-btns-footer .btn.btn-primary{
  margin-right:0 !important;
}