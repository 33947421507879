// View panel
.ViewPanel-section--primary {
  margin-bottom: rem-calc(44);
}
.ViewPanel-header {
  margin-bottom: rem-calc(20);
}
.ViewPanel-section--primary .Section-body {
  padding-bottom: 0;
}
.ViewPanel-section .cord-body.collapse {
  display: none;
}

// Create view
.FilterPanel--createView .ViewPanel-section {
  padding: rem-calc(20);
}
.FilterPanel--createView .ViewPanel-section--primary {
  background-color: bento-color(blueGray, 100);
  border-bottom: 1px solid bento-color(gray, 300);
}
