// Filter panel
.FilterPanel {
  border-top: 1px solid bento-color(gray, 300);
  display: flex;
  flex-direction: row;
  padding: 0;
  width: 100%;
}
.FilterPanel-control {
  background-color: bento-color(white, 600);
  border-right: 1px solid bento-color(gray, 300);
  flex-grow: 1;
  max-width: $filterPanel-width;
  min-width: $filterPanel-width;
}
.FilterPanel-control--Modal {
  box-shadow: 1px 1px 4px 0 rgb(0 0 0 / 20%);
  display: none;
  max-width: $filterPanel-width;
  min-width: $filterPanel-width;
}
.FilterPanel-control--Modal.is-open {
  align-items: baseline;
  display: block;
  height: fit-content;
  overflow: visible;
  position: absolute;
  top: 6.7rem;
  z-index: 1200 !important; // Setting value 999 because header dropdown showing up at 1000 index value.

  @include media('<=small') {
    top: 11.1rem;
  }
  // Below CSS will adjust start position of filterpanel
  // for extra small screen with 400% Zoom
  @media only screen and (max-width: 400px) {
    top: 13.8rem;
  }
}
.FilterPanel-control-scroll.FilterPanel-control--Modal.is-open {
  overflow: hidden;
}
.FilterPanel-data .row {
  margin-left: 0;
}
.Filter-buttons {
  margin-top: 12px;
}
.Filter-buttons > button {
  margin-right: rem-calc(10);
}
.FilterPanel-control--Timekeeper {
  width: calc(100%);
}
.FilterPanel-footer--Modal {
  align-items: center;
  background: #f5f7ff;
  border: 1px solid #d0d0d0;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  padding: rem-calc(12);
}
.FilterPanel-footer--Modal > button {
  margin-left: rem-calc(12);
}
.btn-clear-filter {
  background-color: transparent;
  border: none;
  border-radius: 2px;
  border-style: solid;
  border-width: 1px;
  color: #083899;
  cursor: default;
  display: inline-block;
  font-family: 'Source Sans Pro', Arial, sans-serif;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.25rem;
  padding: 0.3125rem 0.9375rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  vertical-align: middle;
  white-space: normal;

  &:hover {
    box-shadow: 1px 1px 4px 0 rgb(0 0 0 / 20%);
    color: #083899;
  }
  &:disabled {
    border: none;
    color: silver;
    cursor: none;
    pointer-events: none;
    user-select: none;
  }
}
.FilterPanel-header {
  padding: rem-calc(16) rem-calc(20) 0;
  padding-left: 1.2rem !important;
}
.FilterPanel-description {
  margin-bottom: 0;
  padding-left: 1.2rem !important;
}
.axis {
  margin-left: 20px;
}
.FilterPanel-body {
  padding-bottom: rem-calc(16);
}
.FilterPanel-body .form-group {
  margin-bottom: 0;
  padding: rem-calc(4) rem-calc(20);
}
.FilterPanel-body .Form-group--indent {
  padding-left: rem-calc(40);
}
.FilterPanel-body bento-combobox.bento-select input {
  padding-left: rem-calc(12);
}
.FilterPanel-footer {
  padding: rem-calc(16) rem-calc(20);
}
.FilterPanel-dataGrid {
  padding: rem-calc(20);
}

// Filter panel toggle
.FilterPanel--toggle {
  overflow: hidden;
  transform: translateX(#{rem-calc(-336)});
  transition: transform 0.2s, width 0.2s;
  width: calc(100% + #{rem-calc(336)});
}
.FilterPanel--toggle--Modal {
  border: none;
  overflow: visible;
  transform: none;
  transition: transform 0.2s, width 0.2s;
  width: calc(100%);
}
.FilterPanel--toggle.is-open {
  transform: translateX(0);
  width: 100%;
}
.FilterPanel--toggle--Modal.is-open {
  display: block;
  transform: translateX(0);
  width: 100%;
}
.FilterPanel--toggle .FilterPanel-control {
  max-width: rem-calc(400);
}
.FilterPanel--toggle .FilterPanel-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: rem-calc(12);
  position: relative;
}
.FilterPanel--toggle--Modal .FilterPanel-header {
  border-bottom: 1px solid bento-color(gray, 300);
}
.FilterPanel--toggle .FilterPanel-heading {
  margin-bottom: 0;
  padding: rem-calc(4);
}
.FilterPanel--toggle .CreatView-FilterPanel-heading {
  display: flex;
  justify-content: space-between;
  padding: 0;
}
.FilterPanel--toggle .FilterPanel-body {
  padding-top: rem-calc(16);
}
.FilterPanel--toggle .FilterPanel-list {
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
}
.FilterPanel--toggle .FilterPanel-listItem {
  display: flex;
  padding: rem-calc(8) rem-calc(20);
}
.FilterPanel--toggle .FilterPanel-display,
.FilterPanel--toggle .FilterPanel-display-checkbox,
.FilterPanel--toggle .FilterPanel-input {
  flex-grow: 1;
}
.FilterPanel--toggle .FilterPanel-display {
  margin-top: rem-calc(24);
  max-width: rem-calc(32);
}
.FilterPanel--toggle .FilterPanel-display-checkbox {
  max-width: rem-calc(32);
}
.FilterPanel--toggle .FilterPanel-data {
  width: calc(100% - #{rem-calc(400)});
}
.FilterPanel--toggle--Modal .FilterPanel-data {
  width: calc(100%);
}
// Create view
.FilterPanel--createView .FilterPanel-data {
  flex-grow: 1;
  max-width: calc(100% - #{$filterPanel-width});
  min-width: calc(100% - #{$filterPanel-width});
}
.FilterPanel--createView .ViewPanel-section--primary {
  margin-bottom: 0;
}
// Adjust veritcal position of busy loader spinner
.FilterPanel--createView .FilterPanel-grid > .bento-busyloader-blocker {
  bottom: auto;
  height: calc(100vh - #{$header-height} - #{$header-toolbar-height});
}
.FilterPanel-separator {
  margin-bottom: 0;
  padding: rem-calc(5) 0 rem-calc(5) 0;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.mt-30 {
  margin-top: 30px !important;
}
.chart-container {
  height: 100%;
  overflow-y: auto;
}
.chart-filter-container {
  height: 100% !important;
  overflow-y: auto !important;
}
.mr-10 {
  margin-right: 10px;
}
.resetButton {
  color: #005da2;
  cursor: pointer;
  padding: rem-calc(4) rem-calc(20);
}
.footer {
  align-items: center;
  background-color: white;
  border-top: 1px solid grey;
  bottom: 0;
  display: flex;
  height: 100%;
  justify-content: center;
  padding-top: 10px;
  position: sticky;
  width: 100%;
  z-index: 100;
}
.asideGl {
  height: fit-content !important;
  overflow-y: auto !important;
}
.asideClass {
  position: fixed !important;
  top: 0 !important;
}
.divClass {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
}
.btnClass {
  width: 45%;
}
.mainClass {
  height: 81%;
  position: absolute;
}
.bento-reset-close-button-custom{
  position: absolute;
    border: none;
    top: 2px;
    right: 5px;
    z-index: 99;
    background: none;
    color: #083899;
    padding: 0;
    width: 28px;
    height: 28px;
    text-align: center;
}
.bento-reset-close-button-custom i{
top: -1px;
}

.bento-reset-close-button-custom:focus {
  border: 1px dashed #083899;
  outline-offset: 0px;
  outline: none;
  border-radius: 2px;
}
.bento-search input:focus {
  outline-width: 2px;
}
.FilterPanel-control--Timekeeper .FilterPanel-header .btn:focus{
  border: 2px dashed #083899;
  outline-offset: 0px;
  border-radius: 2px;
}
