.AspectRatio {
  position: relative;
  width: 100%;
}
.AspectRatio-target {
  position: absolute;
  top: 0;
}

// Sizes
.AspectRatio--1x1 {
  padding-top: 100%;
}
.AspectRatio--3x2 {
  padding-top: 66.67%;
}
.AspectRatio--16x9 {
  padding-top: 56.25%;
}
.AspectRatio--21x9 {
  padding-top: 42.86%;
}
