// ----------------------------------------------------
// Sets
// ----------------------------------------------------
.Form-set {
  margin-bottom: rem-calc(8);
}
.Form-legend {
  margin-bottom: 0;
}
.FormSet-heading,
.Form-fieldset .Form-legend {
  margin-bottom: rem-calc(4);
}
.FormSet .form-check {
  padding-left: 0;
}
.Form-fieldset--divider {
  border-bottom: 1px solid bento-color(gray, 400);
  margin-bottom: rem-calc(12);
  padding-bottom: rem-calc(12);

  &:last-child {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
  }
}
.Form-fieldset .form-check-input {
  margin-left: 0;
  margin-right: rem-calc(8);
  position: relative;
}

// ----------------------------------------------------
// Prefix
// ----------------------------------------------------
.FormPrefix {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
}
.FormPrefix-name {
  color: bento-color(gray, 600);
  font-size: rem-calc(14);
  margin-right: rem-calc(4);
  min-width: rem-calc(32);
  white-space: nowrap;
}
.FormPrefix .form-control {
  width: calc(100% - #{rem-calc(36)});
}
.FormPrefix bfm-error-container {
  width: 100%;
}

// ----------------------------------------------------
// Inputs
// ----------------------------------------------------
.Form-input {
  max-width: 100%;
  position: relative;
}

// Modifiers
.Form-input--extraSmall {
  max-width: $formInput-extraSmallWidth;
}
.Form-input--small {
  max-width: $formInput-smallWidth;
}
.Form-input--medium {
  max-width: $formInput-mediumWidth;
}
.Form-input--large {
  max-width: $formInput-largeWidth;
}

// States
.Form-input .form-control[disabled] {
  background-color: bento-color(gray, 200);
  color: bento-color(gray, 600);
}

// Bootstrap overrides
.Form-input.row {
  margin-left: $formInput-negativeGutter;
  margin-right: $formInput-negativeGutter;
  max-width: calc(100% + #{$formInput-gutters}); // Adjust for negative margin
}
.Form-input--medium.row {
  max-width: calc(#{$formInput-mediumWidth} + #{$formInput-gutters}); // Adjust for negative margin
}
.Form-input--large.row {
  max-width: calc(#{$formInput-largeWidth} + #{$formInput-gutters}); // Adjust for negative margin
}
.Form-input.row .col {
  padding-left: $formInput-gutter;
  padding-right: $formInput-gutter;
}
.Form-input .bento-select {
  right: auto;
}

// Component specific
.Actions .bento-search .form-control {
  min-width: rem-calc(220);
}

// ----------------------------------------------------
// Groups
// ----------------------------------------------------
// Modifiers
.Form-group--indent {
  padding-left: $formInput-gutters;
}
.Form-group--indent .Form-input {
  max-width: calc(100% - #{$formInput-gutters});
}
.Form-group--indent .Form-input--extraSmall {
  max-width: calc(#{$formInput-extraSmallWidth} - #{$formInput-gutters});
}
.Form-group--indent .Form-input--small {
  max-width: calc(#{$formInput-smallWidth} - #{$formInput-gutters});
}
.Form-group--indent .Form-input--medium {
  max-width: calc(#{$formInput-mediumWidth} - #{$formInput-gutters});
}
.Form-group--indent .Form-input--large {
  max-width: calc(#{$formInput-largeWidth} - #{$formInput-gutters});
}

// ----------------------------------------------------
// Checkboxes
// ----------------------------------------------------
.Form-checkbox {
  margin-bottom: rem-calc(4);

  &:last-child {
    margin-bottom: rem-calc(16);
  }
}

// ----------------------------------------------------
// Combobox
// ----------------------------------------------------
.Form-combobox .bento-list-row {
  height: auto;
  line-height: normal;
}
.Form-combobox .bui-bento-combobox-container-item.bento-list-row > div {
  line-height: normal;
  overflow: visible;
  text-overflow: unset;
  white-space: normal;
}

// ----------------------------------------------------
// Range
// ----------------------------------------------------
.FormRange-group {
  display: flex;
  @include media('<=small') {
    display: block;
  }
}
.FormRange-item {
  flex-grow: 1;
  margin-left: rem-calc(8);

  &:first-child {
    margin-left: 0;
  }
}
.FormRange-item--mediator,
.FormRange-item--action {
  align-items: flex-end;
  display: inline-flex;
  flex-grow: 0;
}
.FormRange-item--mediator {
  justify-content: center;
  margin-bottom: rem-calc(4);
}
.FormRange-item--action {
  margin-bottom: rem-calc(16);
}
.FormRange-item--input {
  margin-left: rem-calc(20);
  max-width: rem-calc(130);

  &:first-child {
    margin-left: 0;
  }
}
.FormRange-item--select,
.FormRange-item--override {
  max-width: rem-calc(140);
}
.FormRange-item--override {
  margin-left: rem-calc(44);
}

.timeKeepers{
  .FilterPanel--toggle .FilterPanel-listItem{
    @include media('<=small') {
      width: 80%!important;
    }
  }
 
  .FilterPanel--toggle .FilterPanel-data {
    @media (max-width: 290px)  {
      max-width: 100% !important;
      width: 100% !important;
    }
  }
  .FilterPanel-footer--Modal{
    @include media('<=small') {
      justify-content: flex-start;
    }
  }
}

// ----------------------------------------------------
// Multiselect
// ----------------------------------------------------
.FormMultiselect,
.FormMultiselect .bento-multiselect-overlay-inner,
.FormMultiselect .bento-side-overlay,
.FormMultiselect .bento-side-overlay .bento-side-overlay-toggle,
.FormMultiselect .bento-side-overlay.open .bento-side-overlay-container-wrapper {
  display: block;
}
.FormMultiselect .bento-multiselect-overlay-inner {
  line-height: normal;
}
.FormMultiselect .bento-side-overlay {
  float: none;
}
.FormMultiselect .bento-side-overlay .btn.bento-side-overlay-toggle {
  color: inherit;
  font-weight: bento-weight(normal);
  min-height: rem-calc(32);
  padding-left: rem-calc(12);
  padding-right: rem-calc(12);
  text-align: left;
  width: 100%;

  &:hover {
    box-shadow: none;
  }
  &[disabled] {
    background-color: bento-color(gray, 100);
    border-color: bento-color(gray, 400);
    color: bento-color(gray, 400);
    cursor: not-allowed;
    user-select: none;
  }
}
.FormMultiselect .bento-multiselect-overlay-inner > .bento-multiselect-overlay-label {
  display: none;
}
.FormMultiselect .bento-side-overlay.bento-side-overlay-right .bento-side-overlay-container-wrapper {
  margin-left: 0;
  position: relative;
}
.FormMultiselect .bento-side-overlay .bento-side-overlay-left-arrow {
  display: none;
}
.FormMultiselect .bui-bento-side-overlay-container-wrapper .bento-side-overlay-container {
  height: auto;
}
.FormMultiselect .bui-bento-side-overlay-container-wrapper.show-overlay .bento-side-overlay-container {
  margin-bottom: rem-calc(12);
  position: static;
}
.FormMultiselect .bui-bento-side-overlay-container-wrapper.show-overlay .bento-side-overlay-container,
.FormMultiselect bento-multiselect-list,
.FormMultiselect app-bento-multiselect-list {
  min-width: auto;
}
.FormMultiselect bento-multiselect-list .bento-list-row,
.FormMultiselect app-bento-multiselect-list .bento-list-row {
  height: auto;
  line-height: rem-calc(16);
  padding-bottom: rem-calc(12);
  padding-top: rem-calc(12);
}
.FormMultiselect bento-multiselect-list .bento-multiselect-list-item,
.FormMultiselect app-bento-multiselect-list .bento-multiselect-list-item {
  overflow: visible;
  text-overflow: clip;
  white-space: nowrap;
}
.FormMultiselect .bento-multiselect-search .bento-items-label span {
  @extend %sr-only;
}
.FormMultiselect bento-multiselect-list .nav-pills button,
.FormMultiselect app-bento-multiselect-list .nav-pills button,
.FormMultiselect bento-multiselect-list .bento-search-label,
.FormMultiselect app-bento-multiselect-list .bento-search-label {
  text-transform: lowercase;

  &::first-letter {
    text-transform: capitalize;
  }
}
.FormMultiselect .bento-reset-close-button .bento-icon-close-x {
  top: -1px;
}
.FormMultiselect
  .bui-bento-side-overlay-container-wrapper
  .bento-side-overlay-container
  .bento-side-overlay-container-footer {
  text-align: left;
}

// Extend
.FormMultiselect--extend .bui-bento-side-overlay-container-wrapper.show-overlay .bento-side-overlay-container,
.FormMultiselect--extend bento-multiselect-list,
.FormMultiselect--extend app-bento-multiselect-list {
  min-width: rem-calc(490);
}
.FormMultiselect--extendSmall .bui-bento-side-overlay-container-wrapper.show-overlay .bento-side-overlay-container,
.FormMultiselect--extendSmall bento-multiselect-list,
.FormMultiselect--extendSmall app-bento-multiselect-list {
  min-width: rem-calc(330);
}

/// Modal
.modal-dialog .FormMultiselect .bento-side-overlay.open {
  min-height: rem-calc(378);
}

// ----------------------------------------------------
// Input groups
// ----------------------------------------------------
.FormInputGroup .input-group-append > .input-group-text {
  background-color: bento-color(white);
  border-bottom-right-radius: 2px;
  border-color: bento-color(gray, 800);
  border-top-right-radius: 2px;
  color: bento-color(gray, 900);
  display: inline-flex;
  font-weight: bento-weight(semiBold);
  justify-content: center;
  min-width: rem-calc(32);
  padding: 0 rem-calc(8);
}

// ----------------------------------------------------
// Tags
// ----------------------------------------------------
.FormTags.form-control {
  border: none;
}
.label-required-summary::before {
  content: '*';
  font-weight: bold;
  margin-right: 0.3125rem;
}
