/*Zoom CSS*/
// ----------------------------------------------------
// Zoom 200% to 400%
// ----------------------------------------------------
a{word-break: break-word;}
.breadcrumb-item, .breadcrumb-item > a, .breadcrumb-item.active{
  font-size: 0.875rem !important;
}

/* Common Zoom Css*/
@media (max-width: 890px){
.custom-peer-groups-list .DataGrid div[wj-part="ch"], .custom-peer-groups-list .DataGrid .wj-cell{
  height:38px !important;
}
.custom-peer-groups-list .DataGrid .wj-cells{top: 38px !important}
}
@media (max-width: 800px){
  .chart-visualization .displayCss, .chart-list-type .Card-group{
    display: block !important;
  }
  .chart-visualization .displayCss .bento-desc-list-item.w-30, .chart-visualization .displayCss .bento-desc-list-item.w-70,
  .chart-list-type .Card-item--large, .chart-list-type .Card-item--small
  {
    width:100% !important;
  }
  .chart-list-type .Card-item--small{
    padding-left: 0 !important;
  }
  
.sr-cpr-action-btns {
  float: none !important;
  position: relative !important;
  top: 0 !important;
  right: 0 !important;
}
.sr-custom-peergroup-modal .modal-header{
  display: block !important;
  padding: 15px !important;
}
}
@media (max-width: 690px){
  /*.chart-visualization .displayCss{
    display: block !important;
  }
  .chart-visualization .displayCss .bento-desc-list-item.w-30, .chart-visualization .displayCss .bento-desc-list-item.w-70{
    width:100% !important;
  }*/
.FilterPanel--toggle.is-open .FilterPanel-data{display:none !important;}
.FilterPanel--toggle .FilterPanel-data{
  display:block !important; 
}
.FilterPanel--toggle.is-open .FilterPanel-control {
  max-width: rem-calc(670) !important;
}

.FilterPanel--toggle .FilterPanel-control {
  max-width: rem-calc(384) !important;
}
bento-toolbar.global-subnav .navbar-right{
  padding-right: 0.425rem !important;
}
.FilterPanel--toggle.is-open {
  transform: translateX(0);
  width: 97% !important;
}
.content-left{
  float: none !important;
}
.content-right .width-30{
width:40% !important;
}
.content-right .width-50{
width: 60% !important;
}
}
//200% zoom css
@media screen and (max-width: 690px) and (min-width: 601px) {
  .global-header .topbar-right {
      margin-right: 10px !important;
  }
  .Header .Header-item--medium {
    margin-right: .725rem !important;
  }
  .username-text{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width:85px;
  }
}
//zoom 250-300%
@media screen and (max-width: 500px) and (min-width: 330px) {
  .global-header-text .topbar-left{
  margin-left: 8px !important;
  }
  bento-toolbar.global-subnav .navbar-right {
    padding-right: 0.2rem !important;
  }
  .Header .topbar-left{
    height: 45px !important;
    margin-left: 0px !important;
    padding-left: 12px !important;
  }
  .Header .topbar-right .Header-item a{
    padding: 0px 5px !important;
  }
  .HeaderSelectFirm{
    max-width: 4rem !important;
  }
  .Header .Header-item--small{
    margin-right: 0.35rem !important;
  }
  .FilterPanel--visualization {
    padding: 0.5rem !important;
}
.chart-container {
  overflow-y: unset !important;
}
}
//400 zoom css scale 100%
@media (max-width: 350px) {
  /*.Body{
    word-break: break-word !important;
  }*/

  /*.FilterPanel--toggle .FilterPanel-control {
    max-width: rem-calc(320);
  }
  .FilterPanel--toggle .FilterPanel-data {
    width: calc(100% - #{rem-calc(320)});
  }*/
  .Header .topbar-left{
    height: 45px !important;
    margin-left: 0px !important;
    padding-left: 12px !important;
  }
  .Header .topbar-right .Header-item a{
    padding: 0px 5px !important;
  }
  .HeaderSelectFirm{
    max-width: 4rem !important;
  }
  .Header .Header-item--small{
    margin-right: 0.35rem !important;
  }
  .custom-peer-groups-list .DataGrid{max-height:62vh;}
  /*.usermenu-dropdown-item{
    display: block !important;
  }*/
  .global-header .topbar-item {
    height: 42px !important;
    line-height: 42px !important;
}
/*bento-toolbar.global-subnav .navbar-right{
  float: left !important;
}*/
.Header .topbar-right{
  padding-left: 1rem !important;
  margin-left: 0px !important;
  margin-right:0px !important;
}
.Header .Header-item--medium {
  margin-right: 0.25rem !important;
}
.Header .Header-logo{
  margin-right:0px !important;
  margin-left:0px !important;
  padding-left: 0px !important;
}
.global-header .topbar-item .dropdown-toggle{
  padding-left: 4px !important;
}
.chart-visualization .Actions-item{
  margin-left: 0.07rem !important;
}
.Section--overflow .Section-body {
  margin-right: -0.25rem !important;
}
.FilterPanel-data .row {
  margin-left: -15px !important;
}
.container, .container-fluid, .container-xl, .container-lg, .container-md, .container-sm{
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.FilterPanel--visualization{
  padding: 1rem !important;
}
.bento-toolbar.global-subnav .navbar-right{
  padding-right:0px !important;
}
/*.wj-cell.wj-header{width:200px !important;}
.wj-cells.wj-frozen-clone{left:200px !important;}
.wj-cells{width:200px !important;}*/

.username-text{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width:85px;
}
bento-toolbar.global-subnav .navbar-right {
  padding-right: 1rem !important;
}
.FilterPanel--toggle.is-open .FilterPanel-control {
  max-width: rem-calc(300) !important;
  min-width: rem-calc(300) !important;
}
.Actions .bento-search .form-control {
  min-width: 11.75rem !important;
}
.content-right .flexClass{
  display: block !important;
}
.content-right .width-30{
  width:100% !important;
  }
  .content-right .width-50{
  width: 100% !important;
  }
  app-staffing-ratio .card .card-body{
    padding:0.5em !important;
    }
    #first ul{
      padding-left:10px !important;
    }
    #first .pad-20, #first .pad-left-20{padding-left:0px !important;}
/*wj-transposed-grid  .wj-row .wj-header{
  width:140px !important;
  padding: 0.325rem !important;
  white-space: normal;
}
wj-transposed-grid  .wj-cells, [wj-part="cf"], [wj-part="ch"], [wj-part="bl"]{left:140px !important;}
wj-transposed-grid  .wj-cell:not(.wj-hasdropdown){
      text-overflow: unset !important;
}
wj-transposed-grid [wj-part="rh"] {
  width : 140px !important;
}*/
}
//400 zoom scale 125%
@media (max-width: 290px) {
  .FilterPanel--toggle.is-open .FilterPanel-control {
    max-width: rem-calc(245) !important;
    min-width: rem-calc(245) !important;
  }
.FilterPanel--visualization{
  padding: 0.7rem !important;
}
.custom-peer-groups-list .DataGrid{max-height:72vh;}
.sr-custom-peergroup-modal .wj-control.wj-columnfiltereditor button[wj-part=btn-apply] {
  margin-right: 3rem;
}
.chart-container{
  overflow-x:hidden;
}
.FilterPanel--toggle .FilterPanel-data {
  width: calc(100% - #{rem-calc(256)}) !important;
}
  .usermenu-dropdown-item{
    display: block !important;
  }
  .usermenu-dropdown-item .username-text{
    white-space: unset;
    overflow: unset;
    text-overflow: unset;
    width:auto;
  }
  .card .card-body {
    padding: 1.5em !important;
}
.FilterPanel--toggle .FilterPanel-header{
  padding: 0.45rem !important;
}

.wj-flexchart .wj-axis-x .wj-title, .wj-flexchart .wj-axis-y .wj-title, .wj-flexchart .wj-label {
   font-size: 0.75rem !important;
}
.bui-bento-side-overlay-container-wrapper .bento-side-overlay-container, app-bento-multiselect-list{
  min-width:245px !important;
}
.visualization-templates-list .Actions-flex {
  position: relative;
  left:10px;
}
.Actions .bento-search .form-control {
  min-width: 8rem !important;
}
.chart-visualization .Actions{
  display: block !important;
}
.chart-visualization .ViewPanel-header .Actions .Actions-item:first-child{
  float: left;
  margin-right: 0.07rem;
}
.chart-visualization .ViewPanel-header .Actions .Actions-item{
  margin-bottom: 0.07rem !important;
}
.btn-sr-input{
  width:200px !important;
  padding: 0.3125rem 0.3375rem !important
}
}
//400 zoom scale 150%
@media (max-width: 220px) {
  .Main-header {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }
  .GlobalLayout-item{
    padding: 0.25rem !important;
  }
  .Header .topbar-left{
    padding-left:5px !important;
  }
  .Header .topbar-left svg{
    width: 190px;
  }
  
.Header .topbar-right {
  padding-left: 0.625rem !important;
}

.visualization-templates-list .Actions-flex {
    /*flex-flow: unset !important;
    margin-top: 2rem;
    column-gap: 1px;*/
    position: relative;
    left:20px;
}
.FilterPanel--toggle.is-open .FilterPanel-control {
  max-width: rem-calc(190) !important;
  min-width: rem-calc(190) !important;
}
.FilterPanel--toggle .FilterPanel-data {
  width: calc(100% - #{rem-calc(240)}) !important;
}
.bui-bento-side-overlay-container-wrapper .bento-side-overlay-container, app-bento-multiselect-list{
  min-width:195px !important;
}
/*.chart-container {
  height: 100%;
  width:200px;
  overflow-x: auto;
  overflow-y: auto;
}*/
.bento-search{
  width: 60% !important;
}
.wj-legend text{
  inline-size: 170px;
}
.FilterPanel--visualization {
  padding: 0.3rem !important;
}
.FilterPanel--toggle .FilterPanel-header {
  padding: 0.2rem !important;
}
.FilterPanel-header button{position: relative;left:3px;}
.wj-flexchart .wj-axis-x .wj-title, .wj-flexchart .wj-axis-y .wj-title, .wj-flexchart .wj-label {
  font-size: 0.68rem !important;
}
.btn-sr-input{width:170px !important;}
.chart-visualization .ViewPanel-header .col{
  padding-left: 0px !important;
  padding-right: 0px !important;
}
}

