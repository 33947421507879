// Fonts
$font-Default: 'Source Sans Pro', arial, sans-serif;
$font-Code: 'Source Code Pro', monospace;
$font-System: -apple-system, blinkmacsystemfont, 'Segoe UI', roboto, oxygen, ubuntu, cantarell, 'Open Sans',
  'Helvetica Neue', sans-serif;

// Layouts
$header-height: rem-calc(60);
$header-toolbar-height: rem-calc(44);

$sidebar-width: rem-calc(340);
$filterPanel-width: rem-calc(320);

$main-height: rem-calc(40);

// Forms
$formInput-gutter: rem-calc(12);
$formInput-negativeGutter: rem-calc(-12);
$formInput-gutters: calc(#{$formInput-gutter} + #{$formInput-gutter});

$formInput-largeWidth: rem-calc(450);
$formInput-mediumWidth: rem-calc(330);
$formInput-smallWidth: calc(#{$formInput-mediumWidth} / 2 - #{$formInput-gutter}); // Half size of medium - 12px margin
$formInput-extraSmallWidth: rem-calc(100);
