// ----------------------------------------------------
// Global sub nav
// ----------------------------------------------------
bento-toolbar.GlobalSubNav .navbar-nav {
  float: none;
  padding-left: rem-calc(5); // Left aligned nav with logo and content
  padding-right: rem-calc(16);
}
bento-toolbar.GlobalSubNav--rightAlignedSpacer .nav > li:nth-child(3) {
  margin-left: auto;
}
bento-toolbar.GlobalSubNav .nav > li > a,
bento-toolbar.GlobalSubNav .nav > li > button,
bento-toolbar.GlobalSubNav .nav > li > div > button,
bento-toolbar.GlobalSubNav .nav > li > div > ul > li > button {
  font-size: rem-calc(14) !important;
}
bento-toolbar.GlobalSubNav .nav > li > .btn-flat-icon {
  font-size: inherit;
}

// ----------------------------------------------------
// Smart badge
// ----------------------------------------------------
bento-toolbar.GlobalSubNav .navbar-nav > li a .bento-smart-badge,
bento-toolbar.GlobalSubNav .navbar-nav bento-scrollable-toolbar-item a .bento-smart-badge {
  left: rem-calc(4);
  top: rem-calc(0);
}

/*Branding Change for Global Subnav - Requires ovveride of some base CSS*/

/*Main Global Subnav*/
bento-toolbar.global-subnav{
  background-color: #D64000 !important;
  font-family: "Clario", Arial, Helvetica, sans-serif;
}

/*Remove border from maintheme global subnav*/
bento-toolbar.global-subnav .navbar-nav{
  border: none !important;
}

/*Main Global Subnav Drop down*/
bento-toolbar.global-subnav [ngbdropdown] .dropdown-menu, bento-toolbar.global-subnav [ngbdropdown].dropdown-menu, bento-toolbar.global-subnav bento-megamenu .dropdown-menu, bento-toolbar.global-subnav bento-megamenu.dropdown-menu{
  background-color: #D64000 !important;
}

/*Main Global Subnav Drop down a*/
bento-toolbar.global-subnav [ngbdropdown] .dropdown-menu button, bento-toolbar.global-subnav [ngbdropdown] .dropdown-menu a, bento-toolbar.global-subnav [ngbdropdown].dropdown-menu button, bento-toolbar.global-subnav [ngbdropdown].dropdown-menu a, bento-toolbar.global-subnav bento-megamenu .dropdown-menu button, bento-toolbar.global-subnav bento-megamenu .dropdown-menu a, bento-toolbar.global-subnav bento-megamenu.dropdown-menu button, bento-toolbar.global-subnav bento-megamenu.dropdown-menu a{
  background-color: #D64000 !important;
  color: #fff !important;
  &:hover{     
    background-color: #802600 !important; 
  }

}
/*Main Global Subnav a*/
bento-toolbar.global-subnav .navbar-nav > li > button, bento-toolbar.global-subnav .navbar-nav > li > a, bento-toolbar.global-subnav .navbar-right > li > a, bento-toolbar.global-subnav .navbar-nav > li > [ngbdropdown] > button, bento-toolbar.global-subnav .navbar-nav > li > [ngbdropdown] > a{
  //background-color: #D64000 !important;
  color: #fff !important;
  &:hover, &:active, &:focus{
    background-color: #802600 !important; 
    font-weight: 400 !important;
  }
  &.active{
    background-color: #802600 !important; 
    font-weight: 400 !important;
  }
}

/*Removing border from active element*/
bento-toolbar.global-subnav .navbar-nav > li > a.active:before, bento-toolbar.global-subnav .navbar-nav > li > a.router-link-active:before{
  height: 0px;
  background-color: transparent;
}

/*Resources tab*/
bento-toolbar.global-subnav .navbar-right > li > a.active:before, bento-toolbar.global-subnav .navbar-right > li > a.router-link-active:before{
  height: 0px;
  background-color: transparent;
}


.bento-toolbar.global-subnav .navbar-nav > li > button.active:before, bento-toolbar.global-subnav .navbar-nav > li > button.router-link-active:before, bento-toolbar.global-subnav .navbar-nav > li > a.active:before, bento-toolbar.global-subnav .navbar-nav > li > a.router-link-active:before, bento-toolbar.global-subnav .navbar-right > li > a.active:before, bento-toolbar.global-subnav .navbar-right > li > a.router-link-active:before, bento-toolbar.global-subnav .navbar-nav > li > [ngbdropdown] > button.active:before, bento-toolbar.global-subnav .navbar-nav > li > [ngbdropdown] > button.router-link-active:before, bento-toolbar.global-subnav .navbar-nav > li > [ngbdropdown] > a.active:before, bento-toolbar.global-subnav .navbar-nav > li > [ngbdropdown] > a.router-link-active:before{
  background-color: #fff !important;
}


/*Child pages heading font size should match main heading*/
bento-toolbar.GlobalSubNav .nav > li > a, bento-toolbar.GlobalSubNav .nav > li > button, bento-toolbar.GlobalSubNav .nav > li > div > button, bento-toolbar.GlobalSubNav .nav > li > div > ul > li > button{
  font-size: 1rem !important;
}

bento-toolbar.global-subnav .navbar-nav > li > a.router-link-active{
  background-color: #802600 !important;
  font-weight: 400 !important;
}