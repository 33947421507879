// ----------------------------------------------------
// Shared
// ----------------------------------------------------
.GlobalLayout,
.Main,
.AppLayout {
  @include media('>=medium') {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
}
a, button{cursor: pointer !important;}
// ----------------------------------------------------
// Body
// ----------------------------------------------------
.Body {
  /*min-width: rem-calc(240);*/
  overflow-x: hidden;
}

// ----------------------------------------------------
// App ayout
// ----------------------------------------------------
.AppLayout {
  min-height: 100%;
}

// ----------------------------------------------------
// Global layout
// ----------------------------------------------------
// TODO: Refactor when off canvas menu is added. Story 18330
.GlobalLayout {
  @include media('>=medium') {
    min-height: calc(100% - #{$header-height} - #{$header-toolbar-height});
    overflow: hidden;
  }
}
.GlobalLayout-group {
  border-top: 1px solid #858585; //bento-color(gray, 400)

  @include media('>=medium') {
    flex-grow: 1;
  }
}
.GlobalLayout-item {
  max-width: 100%;
  padding: rem-calc(20);
}

// Two column
.GlobalLayout--twoColumn .GlobalLayout-group {
  @include media('>=medium') {
    display: grid;
    grid-template-areas: 'sidebar main';
    grid-template-columns: $sidebar-width calc(100% - #{$sidebar-width});
    grid-template-rows: 100%;
  }
}
.GlobalLayout--twoColumn .GlobalLayout-item--small {
  border-top: 1px solid #858585; //bento-color(gray, 400)
  padding: 0;

  @include media('>=medium') {
    border-right: 1px solid #858585;//bento-color(gray, 400)
    border-top: 0;
  }
}

// Component specific
.bento-tabset .GlobalLayout-group {
  border-top: 0;
}

// ----------------------------------------------------
// Main
// ----------------------------------------------------

// Descendents
.Main-header {
  padding-left: rem-calc(20);
  padding-right: rem-calc(20);
  padding-top: rem-calc(20);
}
.Main-header .row {
  @include media('<=small') {
    display: block;
  }
}
.Main-heading {
  flex-grow: 1;
  margin-bottom: 0;
}
.Main-body {
  padding: rem-calc(20);

  @include media('>=medium') {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
}
.Main-body--viewgrid {
  padding: rem-calc(0) rem-calc(20) rem-calc(0) rem-calc(20);

  @include media('>=medium') {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
}
.Main-footer {
  padding: 0 rem-calc(20) rem-calc(20);
}

// Modifiers
.Main--narrow {
  max-width: rem-calc(740);
}
.Main--center {
  margin-left: auto;
  margin-right: auto;
  padding: rem-calc(20);

  @include media('>=small') {
    padding-bottom: rem-calc(48);
    padding-top: rem-calc(48);
  }
}
.Main--mansory {
  @include media('>=small') {
    column-gap: rem-calc(20);
    display: grid;
    grid-template-columns: calc(440 / 700 * 100%) calc(240 / 700 * 100%); // No IE support
    grid-template-rows: auto auto;
    row-gap: rem-calc(20);
  }
}
.Main--zeroState {
  height: calc(100vh - #{$header-height});
}
.Main-header--border {
  border-bottom: 1px solid bento-color(gray, 300);
}
.Main-header--border .Main-heading {
  margin-bottom: rem-calc(20);
}
.Main-body--light {
  background-color: bento-color(blueGray, 100);
}
.Main-header--summary {
  padding: rem-calc(0) rem-calc(0) rem-calc(20) rem-calc(0);
}
.Main-body--NoPadding {
  padding-top: 0;
}

// Component specific
.GlobalLayout--twoColumn .Main-header {
  margin-bottom: rem-calc(20);
}
.GlobalLayout--twoColumn .Main-body {
  padding: 0;
}

// ----------------------------------------------------
// Main Section
// ----------------------------------------------------
.MainSection,
.MainSection-listItem {
  margin-bottom: rem-calc(20);
}
.MainSection {
  border: 1px solid bento-color(gray, 400);

  @include media('>=small') {
    margin-bottom: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

// Descendents
.MainSection-header {
  background-color: bento-color(gray, 200);
  border-bottom: 1px solid bento-color(gray, 400);
}
.MainSection-heading {
  font-weight: weight(semiBold);
  margin-bottom: 0;
}
.MainSection-list {
  list-style: none;
  margin: 0;
  padding: 0;
}
.MainSection-listItem {
  &:last-child {
    margin-bottom: 0;
  }
}
.MainSection-listDate {
  display: block;
}

// Modifiers
.MainSection--small .MainSection-header,
.MainSection--small .MainSection-body,
.MainSection--small .MainSection-footer,
.MainSection--large .MainSection-header,
.MainSection--large .MainSection-body,
.MainSection--large .MainSection-footer {
  padding: rem-calc(20);
}
.MainSection--small .MainSection-header,
.MainSection--small .MainSection-body,
.MainSection--small .MainSection-footer {
  @include media('>=small') {
    padding: rem-calc(12);
  }
}
.Main--mansory .MainSection--large {
  @include media('>=small') {
    grid-row-end: 3;
    grid-row-start: 1;
  }
}

// ----------------------------------------------------
// Section
// ----------------------------------------------------
.Section--narrow {
  max-width: rem-calc(900);
}
.Section--details {
  margin-top: rem-calc(44);
}
.Section--views .Section-header {
  padding-bottom: rem-calc(12);
}
.Section--overlap .Section-header {
  margin-bottom: 0;
  padding-bottom: 0;
  position: relative;
}
.Section--views .Section-heading,
.Section--stats .Section-heading,
.Section--details .Section-heading {
  margin-bottom: 0;
}
.Section--dataGrid .Section-body {
  padding-top: rem-calc(20);
}
.Section--overflow .Section-body {
  margin-right: rem-calc(-20);

  @include media('>=extraLarge') {
    margin-right: 0;
  }
}
.Section--views .Section-body {
  padding-bottom: rem-calc(4);
}

// Component specific
.FilterPanel--createView .Section--details {
  margin-top: rem-calc(20);
}
.ViewPanel-section--details .Section--details {
  margin-top: rem-calc(24);
}
.GlobalLayout .Section-header {
  margin-bottom: rem-calc(24);
}
.GlobalLayout-item--large .Section-header,
.GlobalLayout .Section--overlap .Section-header {
  margin-bottom: 0;
}
.FilterPanel--createView .Section--views .Section-heading {
  font-size: rem-calc(20);
}
.FilterPanel--createView .Section--details .Section-heading {
  font-size: rem-calc(18);
}

// TODO: Bento bug - temporary solution
.Main .bento-busyloader-blocker {
  max-width: 100% !important;
  width: 100vw !important;
  z-index: 2001;
}
.Main .bento-busyloader-front-blocker {
  z-index: 2000;
}
bento-skip-links {
  z-index: 3000;
}
.bui-bento-side-overlay-container-wrapper .bento-side-overlay-container .bento-side-overlay-container-footer {
  @include media('<=medium') {
    border-top-style: none !important;
    height: 10px !important;
    left: initial !important;
    padding: 0 !important;
    right: 10px !important;
    text-align: right !important;
    top: 43px;
  }
}
app-bento-multiselect-list .nav-pills > li button {
  @include media('<=medium') {
    padding: 0 !important;
  }
}
app-bento-multiselect-list .pills {
  @include media('<=medium') {
    margin: -10px -10px 0 !important;
  }
}
.bento-items-label {
  @include media('<=medium') {
    margin-top: 0 !important;
  }
}
app-bento-multiselect-list {
  bento-list {
    min-height: 60px;
  }
}
.padding-top-right {
  padding-left: 1.2rem;
  padding-top: 1.2rem;
}
.padding-left {
  padding-left: 1.2rem;
}
.no-bottom-margin {
  margin-bottom: 0;
}
.no-top-padding {
  padding-top: 0 !important;
}
.bento-search {
  @include media('<=medium') {
    width: 70%;
  }
}

// Settings min-height to combobox container and bento list
.bui-bento-combobox-container bento-list {
  min-height: 80px;
}
.bui-bento-combobox-container-list {
  min-height: 80px;
}
.dm-combo {
  width: 200px;
}
.imgContent {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 30%;
}
.imgDetails {
  padding-left: 2rem;
  width: 70%;
}
.image-icon-body {
  height: 130px;
  width: -webkit-fill-available;
}
.chart-meta {
  padding-top: 0.3rem;
}
.padding-7 {
  padding: 7px;
}
.BalancingDetails {
  padding-bottom: rem-calc(20);
}
.create-view-main .Section-header .col{
  padding-left:0px;
}
.note-info{
  font-size: 16px;
  background-color: #f4f4f4;
  padding: 10px;
}
.save-vs-modal-content h3{
  width: 90%;
  border-bottom:0 !important;
}
.save-vs-modal-content .modal-body{
  border-top: solid 1px #afafaf;
}
/*Accessibility color changes*/
bento-toolbar.global-subnav .navbar-nav > li > button.active:before, bento-toolbar.global-subnav .navbar-nav > li > button.router-link-active:before, bento-toolbar.global-subnav .navbar-nav > li > a.active:before, bento-toolbar.global-subnav .navbar-nav > li > a.router-link-active:before, bento-toolbar.global-subnav .navbar-right > li > a.active:before, bento-toolbar.global-subnav .navbar-right > li > a.router-link-active:before, bento-toolbar.global-subnav .navbar-nav > li > [ngbdropdown] > button.active:before, bento-toolbar.global-subnav .navbar-nav > li > [ngbdropdown] > button.router-link-active:before, bento-toolbar.global-subnav .navbar-nav > li > [ngbdropdown] > a.active:before, bento-toolbar.global-subnav .navbar-nav > li > [ngbdropdown] > a.router-link-active:before{
  background-color: #5BA091;
}

bento-side-nav > ul > [bentoSideNavItem]{
  border-bottom-color: #858585 //#afafaf
}
bento-side-nav {
  border-right-color: #858585;
  border-left-color: #858585;
}
.card{
  border: solid 1px #858585
}
.MainSection-header--slim,
.MainSection--slim .MainSection-header {
  
  background-color: transparent;
  border-bottom: none;
}
.MainSection-header--slim {
  padding: rem-calc(20) rem-calc(20) 0;
}
.MainSection--slim .MainSection-header {
  padding: rem-calc(16) rem-calc(16) 0;
}
.MainSection--slim .MainSection-body,
.MainSection--slim .MainSection-footer {
  
  padding: rem-calc(16);
}

bento-multiselect-list .nav-pills > li button:focus span{
  outline: 2px dashed #083899 !important;
}
bento-multiselect-overlay bento-list.cdk-program-focused, bento-multiselect-overlay bento-list.cdk-keyboard-focused{
  outline: none !important;
}

bento-multiselect-overlay bento-list.cdk-program-focused .focused bento-checkbox, bento-multiselect-overlay bento-list.cdk-keyboard-focused .focused bento-checkbox{
  box-shadow: none !important;
    outline: 1px dashed #083899 !important;
    outline-offset: 0 !important;
    z-index: 1;
    outline-style: solid !important;
}

bento-multiselect-list .bento-multiselect-list-item.focused{
  background: #e3e8fa;
}

.modal-footer-visualization.modal-footer {
    flex-direction: initial !important;
    justify-content: flex-end !important;
  }
