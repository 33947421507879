// ----------------------------------------------------
// Global Header
// ----------------------------------------------------
.Header {
  z-index: 1119; // made z-index less than Modal z-index

  @include media('<=medium') {
    height: auto;
  }
}
.Header .Header-logo {
  border: 1px solid transparent;
  line-height: rem-calc(54);
  margin-left: rem-calc(-12);
  padding-left: rem-calc(12);

  &:hover {
    border-color: bento-color(white);
  }
}
.Header .topbar-left {
  @include media('<=medium') {
    display: block;
  }
}
.Header .topbar-right {
  @include media('<=medium') {
    display: block;
    padding-bottom: rem-calc(12);
    padding-left: rem-calc(10);
    position: static;
    right: auto;
    width: 100%;
  }
}
.Header .topbar-left .Header-logo {
  padding-right: rem-calc(12) !important;
}
.Header .Header-item--small {
  margin-right: rem-calc(8);
}
.Header .Header-item--medium {
  margin-right: rem-calc(20);
}
.Header .topbar-right .Header-item a,
.Header .topbar-right .Header-item button {
  display: inline-flex;
  font-size: rem-calc(14);
  height: rem-calc(38);
}
.Header .topbar-right .Header-item a {
  border-width: 1px;
}
.Header .topbar-right .btn.dropdown-toggle:active,
.Header .topbar-right .show > .btn.dropdown-toggle {
  background-color: transparent;
  color: bento-color(white);
}
.Header .topbar-right .bento-icon-close-circle {
  min-width: rem-calc(16);
}
.Header .topbar-right .btn.dropdown-toggle .btn-text + .bento-icon-caret-down-filled,
.Header .topbar-right .btn.dropdown-toggle .btn-text + .bento-icon-close-circle {
  margin-left: rem-calc(2);
}
.HeaderSelectFirm {
  max-width: rem-calc(80);
}
.HeaderSelectFirm-ID {
  width: 100%;
}
.Header .topbar-right .HeaderSelectFirm .form-control {
  font-size: rem-calc(13) !important;
}
.Header .dropdown-menu .copyright {
  background-color: bento-color(gray, 200);
  border-top: 1px solid bento-color(gray, 400);
  font-size: rem-calc(14);
  padding: rem-calc(8) rem-calc(16);
}
.modal-content {
  @include media('<=medium') {
    overflow: auto !important;
  }
}
.modal .modal-dialog {
  @include media('<=medium') {
    max-height: 100%;
  }
}
.modal .modal-content {
  @include media('<=medium') {
    max-height: 100%;
  }
}
.modal-content .modal-header {
  @include media('<=medium') {
    padding: 0;
  }
}
.modal-content > .close,
.modal-content > bento-modal-confirmation > .close {
  @include media('<=medium') {
    top: 0;
  }
}
.modal-content .modal-body {
  @include media('<=medium') {
    padding: 1rem;
  }
}
.modal-content > .modal-footer--custom {
  flex-direction: initial;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}
.toolbar-dropdown-toggle::after {
  display: none !important;
}

#selectFirmDropdown ul, #selectFirmDropdown ul li{
  margin:0px;
  padding:0px;
  list-style: none;
}
.dropdown-menu li .dropdown-item {
  padding: 0.5rem 1rem;
  font-family: 'Clario', Arial, Helvetica, sans-serif !important;
}

.global-header {
  background-color: #3b3f4a;
  background-color: #123015;
}

.global-header .topbar-middle, .global-header .topbar-right {
  background-color: #3b3f4a;
  background-color: #123015; 
}


.global-header .topbar-right{
 
  .btn, .btn-secondary,.btn-ghost{
    font-family: 'Clario', Arial, Helvetica, sans-serif !important;
  }
}
.HeaderSelectFirm .form-control{
  font-family: 'Clario', Arial, Helvetica, sans-serif !important;
  .bento-list-row.bento-combobox-container-item.bui-bento-combobox-container-item{
    font-family: 'Clario', Arial, Helvetica, sans-serif !important;
    font-size: 0.8rem !important;
  }
}

.HeaderSelectFirm.form-control{
  font-family: 'Clario', Arial, Helvetica, sans-serif !important;
  font-size: 0.8rem !important;
  .bento-list-row.bento-combobox-container-item.bui-bento-combobox-container-item{
    font-family: 'Clario', Arial, Helvetica, sans-serif !important;
    font-size: 0.8rem !important;
  }
}


ngb-toast.bento-toast-default .close:hover, ngb-toast button.bento-toast-action-btn.btn-outline-primary:hover, .btn-secondary.btn-ghost:hover, .btn-outline-secondary.btn-icon.btn-ghost:hover, .btn-flat-secondary.btn-ghost:hover, .btn-flat-icon.btn-ghost:hover{
  background-color: #4F7B60 !important ;
}

.Actions-item-container{
  .Actions.justify-content-end{
      div.Actions-item{
          display: flex !important;
      }
  }
}

.landing-page{
h1, .h1{
 font: 600 1.5rem / 2.5rem "Source Sans Pro", Arial, sans-serif
}
.Main-heading{
  margin-bottom: 0px;
}
}

