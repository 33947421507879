@media (min-width:1200px){.staffing-ratio-page bento-alert .alert-container{width:50% !important;}}
#srfileuplod {
  display: none;
}
.paneDesign {
  font-family: 'Source Sans Pro';
  font-size: medium;
  font-weight: 600;
}
.content-right {
  overflow: hidden;
  min-height: 200px;
  padding-top: 15px;
  padding-left: 3%;
}
.content-left {
  float: left;
  margin-right: 25px;
  min-height: 200px;
}
.content-left img{
  max-width: 100%;
}
.pad-20{
  padding:20px;
}
.pad-left-20{padding-left:20px;}
.content-right.body01 .pad-left-20{padding-bottom: 2px;}
.btn-sr-input{width:220px;}
.width-30{width:30%;}
#listOfPeerGroup{padding-top: 2%;}
#listOfCutomPeerGroup{padding-top: 1%}
.submit-peer-group-sec{padding-top: 4%}
#part3{min-height: 309.875px}
.Main-heading{margin-bottom:20px;}
.Main-heading-mb-0{
  margin-bottom:0px !important;
}
.ml-10 {
    margin-left: 10px !important;
  }
  .flexClass {
    display: flex;
    width: 100%;
  }
  .content-right .width-50 {
    width: 65%;
  }
  .font-style {
    //font-size: 10px;
    font-weight: 400 !important;
  }
  .print-head{display:none;}
  @media print {
    .cpg-info-para, .model-header-sec{display: none;}
    .print-head{display:block; text-align: center;}
}

.modal.sr-custom-peergroup-modal .modal-dialog{
  max-width: 95% !important;
}
.modal.sr-custom-peergroup-modal .modal-content{
  min-height: 85vh;
  //height: 100%;
  max-height: 90vh;
}

#sr-custom-peergroup-modal{
  margin-bottom:0px;
  
}
.model-header-sec{
  clear:both;
  border: none !important;
  padding:1.5rem 2rem 0 2rem !important;
}
.model-header-title-sec{
  float:left;
  width:400px;
}
.sr-cpr-action-btns {
  float:right;
  position: absolute;
  top: 17px;
  right:32px;
}
.sr-cpr-action-btns .btn{
  margin:0px 2px;
}

/*.sr-custom-peergroup-modal input[type="checkbox"]:disabled {
  background-color: gray !important;
  border-color: gray !important;;
  color: linen;
  opacity: 0.6;
}
.sr-custom-peergroup-modal input[type="checkbox"] {
  position: relative;
  width: 17px;
  height: 16px;
  color: gray;
  border: 1px solid gray;
  appearance: none;
  outline: 0;
  cursor: pointer;
  //transition: background 175ms cubic-bezier(0.1, 0.1, 0.25, 1);
  &::before {
    position: absolute;
    content: '';
    display: block;
    top: -1px;
    left: 5px;
    width: 6px;
    height: 13px;
    border-style: solid;
    border-color: #fff;
    border-width: 0 1px 1px 0;
    transform: rotate(45deg);
    opacity: 0;
  }
  &:checked {
    color: #fff;
    border-color: #1d7366;
    background: #1d7366;
    &::before {
      opacity: 1;
    }
    ~ label::before {
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    }
  }
}
*/
.myreport_title{font-weight: bold;}
.pad-btm-0{padding-bottom: 0;}
.sr-total-firms{line-height: 32px;}
.sr-add-firms .DataGrid{
  /*max-height: 600px;*/
}
.sr-add-firms-grid-main .DataGrid{
  margin-bottom:40px;
}
.sr-add-firms-footer{
  position: fixed;
  bottom: -7px;
  background: white;
  width: 100%;
  border-radius: 0;
  padding-top: 10px;
  z-index: 9999;
}
.survey-year-label{
  display: inline-block;
  font-size: 18px;
  position: relative;
  top:-2px;
}
.sr-selected-btn-sec{
  text-align:right;
  }
.sr-selected-btn-sec span{
  display:inline-block;
}
/*.sr-peergroup-firms .note-info{
  padding:5px 10px;
}*/

.d-block.modal.sr-custom-peergroup-modal.show{
  height:  clamp(40vh, 100vh, max(calc(100vh + 20px)));
}

.modal.sr-custom-peergroup-modal .modal-content{
  width: auto;
  height:  clamp(30vh, calc(50vh + 10px), max(90vh));
  overflow-y: auto; 
  overscroll-behavior-y: auto;
}

.custom-peer-groups-list .DataGridFlexScroll{ 
  max-height: clamp(min(30vh), 45vh, max(52vh));
}

bento-checkbox:not(.disabled):hover+label, bento-checkbox:not(.disabled)+label:hover{
  text-decoration: none;
}

.admin-configurations {
  .DataGrid .wj-colheaders .wj-header {
    letter-spacing: 0 !important;
    word-spacing: 0 !important; 
  }
} 

.Actions-item.a11y{
  cursor: not-allowed !important;
  button[aria-disabled="true"] {
    background-color: #f0f0f0!important;
    border-color: #666!important;
    box-shadow: none!important;
    color: #404040!important;
    pointer-events: all !important;
    cursor: not-allowed !important;
    &:hover{
      text-decoration: none !important;
    }
    .btn-text:hover {
      text-decoration: none !important;
    }
  }  
  &:hover{
    text-decoration: none !important;
  }
  .btn:hover span{
    text-decoration: none !important;
  }
} 

.bento-tabset .nav-tabs > .nav-item > .nav-link:focus:before{
  border-width:2px !important;
}
.bento-tabset .nav-tabs > .nav-item > a:focus, .bento-tabset .nav-tabs > .nav-item > a:focus-visible{
outline-width: 0px !important;
}

.staffing-ratio-add-peer-groups{
  .note-info{
    display:flex;    
    @media (max-width: 820px) { 
      display:block;
    }
  }
  .note-1{
    display: block;
    flex-grow: 1;
  }
  .grid-note{
    font-size: 14px;
    .shortcut{
      font-weight: bold;
    }
  }
}